export var ExternalPageUrls;
(function (ExternalPageUrls) {
    ExternalPageUrls["ZaberBaseUrl"] = "https://www.zaber.com";
    ExternalPageUrls["ZaberContact"] = "https://www.zaber.com/contact";
    ExternalPageUrls["ZaberPrivacyPolicy"] = "https://www.zaber.com/privacy-policy";
    ExternalPageUrls["ZaberGitlab"] = "https://gitlab.com/ZaberTech";
    ExternalPageUrls["ZaberSoftware"] = "https://www.zaber.com/software";
    ExternalPageUrls["ZaberNewsletter"] = "https://www.zaber.com/newsletter";
    ExternalPageUrls["ZaberConsole"] = "https://www.zaber.com/wiki/Software/Zaber_Console";
    ExternalPageUrls["ZaberZML"] = "https://software.zaber.com/motion-library";
    ExternalPageUrls["ZaberZMLGettingStarted"] = "https://software.zaber.com/motion-library/docs/tutorials";
    ExternalPageUrls["ZaberProtocolManualBase"] = "https://www.zaber.com/protocol-manual";
    ExternalPageUrls["ZaberLauncherReleaseNotes"] = "https://gitlab.com/ZaberTech/zaber-launcher/-/blob/master/CHANGELOG.md";
})(ExternalPageUrls || (ExternalPageUrls = {}));
export function getProtocolManualLink(data) {
    const params = new URLSearchParams();
    (data === null || data === void 0 ? void 0 : data.device) && params.append('device', data === null || data === void 0 ? void 0 : data.device);
    (data === null || data === void 0 ? void 0 : data.firmware) && params.append('version', `${data === null || data === void 0 ? void 0 : data.firmware.major}.${data === null || data === void 0 ? void 0 : data.firmware.minor}`);
    (data === null || data === void 0 ? void 0 : data.peripheral) && params.append('peripheral', data === null || data === void 0 ? void 0 : data.peripheral);
    params.append('protocol', 'ASCII');
    const url = `${ExternalPageUrls.ZaberProtocolManualBase}?${params.toString()}`;
    if (data === null || data === void 0 ? void 0 : data.warningFlag) {
        return `${url}#topic_message_format_warning_flags__topic_message_format_warning_flags_${data === null || data === void 0 ? void 0 : data.warningFlag}`;
    }
    return url;
}
