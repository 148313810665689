import { useSelector } from 'react-redux';
import { AuthState } from './types';
import { selectAuthState } from './selectors';
export const IfAuth = ({ children, desiredState = AuthState.Authenticated }) => {
    const authState = useSelector(selectAuthState);
    if (authState !== desiredState) {
        return null;
    }
    return children;
};
