import { routerActions } from 'connected-react-router';
import { PageUrls } from '../urls';
import { actionBuilder } from '../utils';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["SIGN_UP"] = "SIGN_UP";
    ActionTypes["SIGN_UP_RESULT"] = "SIGN_UP_RESULT";
    ActionTypes["RESEND_SIGN_UP_EMAIL"] = "RESEND_SIGN_UP_EMAIL";
    ActionTypes["RESEND_SIGN_UP_EMAIL_DONE"] = "RESEND_SIGN_UP_EMAIL_DONE";
    ActionTypes["UPDATE_SIGN_UP_DATA"] = "UPDATE_SIGN_UP_DATA";
    ActionTypes["LOGIN"] = "LOGIN";
    ActionTypes["LOGIN_RESULT"] = "LOGIN_RESULT";
    ActionTypes["UPDATE_LOGIN_DATA"] = "UPDATE_LOGIN_DATA";
    ActionTypes["CHECK_AUTH_RESULT"] = "CHECK_AUTH_RESULT";
    ActionTypes["LOGOUT"] = "LOGOUT";
    ActionTypes["AUTH_CHANGED"] = "AUTH_CHANGED";
    ActionTypes["PASSWORD_RESET_REQUEST"] = "USER_PASSWORD_RESET_REQUEST";
    ActionTypes["PASSWORD_RESET_REQUEST_DONE"] = "USER_PASSWORD_RESET_REQUEST_DONE";
    ActionTypes["PASSWORD_RESET_SUBMIT"] = "USER_PASSWORD_RESET_SUBMIT";
    ActionTypes["PASSWORD_RESET_SUBMIT_DONE"] = "USER_PASSWORD_RESET_SUBMIT_DONE";
    ActionTypes["PASSWORD_RESET_CLEAR"] = "USER_PASSWORD_RESET_CLEAR";
    ActionTypes["USER_ATTRIBUTES_UPDATE"] = "USER_ATTRIBUTES_UPDATE";
    ActionTypes["USER_ATTRIBUTES_UPDATED"] = "USER_ATTRIBUTES_UPDATED";
    ActionTypes["USER_PASSWORD_CHANGE"] = "USER_PASSWORD_CHANGE";
    ActionTypes["USER_PASSWORD_CHANGED"] = "USER_PASSWORD_CHANGED";
    ActionTypes["USER_ACCOUNT_DELETE"] = "USER_ACCOUNT_DELETE";
    ActionTypes["USER_ACCOUNT_DELETED"] = "USER_ACCOUNT_DELETED";
    ActionTypes["RESET_ERRORS"] = "USER_RESET_ERRORS";
})(ActionTypes || (ActionTypes = {}));
const buildAction = (type, value) => actionBuilder(type, value);
export const actions = {
    signUp: () => buildAction(ActionTypes.SIGN_UP),
    signUpResult: (error) => buildAction(ActionTypes.SIGN_UP_RESULT, { error }),
    resendSignUpEmail: (email) => buildAction(ActionTypes.RESEND_SIGN_UP_EMAIL, { email }),
    resendSignUpEmailDone: (error) => buildAction(ActionTypes.RESEND_SIGN_UP_EMAIL_DONE, { error }),
    updateSignUpData: (data) => buildAction(ActionTypes.UPDATE_SIGN_UP_DATA, data),
    login: () => buildAction(ActionTypes.LOGIN),
    loginResult: (loggedInUser) => buildAction(ActionTypes.LOGIN_RESULT, { loggedInUser }),
    loginResultErr: (error) => buildAction(ActionTypes.LOGIN_RESULT, { error }),
    updateLoginData: (data) => buildAction(ActionTypes.UPDATE_LOGIN_DATA, data),
    checkAuthResult: (loggedInUser) => buildAction(ActionTypes.CHECK_AUTH_RESULT, { loggedInUser }),
    goToLogin: () => routerActions.replace(PageUrls.Login),
    logout: () => buildAction(ActionTypes.LOGOUT),
    authChanged: (userIsLoggedIn, isInitialCheck) => buildAction(ActionTypes.AUTH_CHANGED, { userIsLoggedIn, isInitialCheck }),
    updateUserAttributes: (userDataToUpdate) => buildAction(ActionTypes.USER_ATTRIBUTES_UPDATE, { userDataToUpdate }),
    userAttributesUpdated: (userData) => buildAction(ActionTypes.USER_ATTRIBUTES_UPDATED, { userData }),
    userAttributesUpdateError: (error) => buildAction(ActionTypes.USER_ATTRIBUTES_UPDATED, { error }),
    changeUserPassword: (oldPassword, newPassword) => buildAction(ActionTypes.USER_PASSWORD_CHANGE, { oldPassword, newPassword }),
    userPasswordChanged: () => buildAction(ActionTypes.USER_PASSWORD_CHANGED, {}),
    userPasswordChangeError: (error) => buildAction(ActionTypes.USER_PASSWORD_CHANGED, { error }),
    requestPasswordReset: (email) => buildAction(ActionTypes.PASSWORD_RESET_REQUEST, { email }),
    passwordResetRequestDone: (error) => buildAction(ActionTypes.PASSWORD_RESET_REQUEST_DONE, { error }),
    submitPasswordReset: (email, resetCode, newPassword) => buildAction(ActionTypes.PASSWORD_RESET_SUBMIT, { email, resetCode, newPassword }),
    passwordResetSubmitDone: (error) => buildAction(ActionTypes.PASSWORD_RESET_SUBMIT_DONE, { error }),
    passwordResetClear: () => buildAction(ActionTypes.PASSWORD_RESET_CLEAR),
    deleteUserAccount: (email, password) => buildAction(ActionTypes.USER_ACCOUNT_DELETE, { email, password }),
    userAccountDeleted: () => buildAction(ActionTypes.USER_ACCOUNT_DELETED, {}),
    userAccountDeleteError: (error) => buildAction(ActionTypes.USER_ACCOUNT_DELETED, { error }),
    resetErrors: () => buildAction(ActionTypes.RESET_ERRORS),
};
