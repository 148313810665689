import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { HeaderCard, Icons, NoticeBanner, Text } from '@zaber/react-library';
import { useActions } from '../utils';
import { actions as actionsDefinition } from './actions';
export const Axis = ({ axis }) => {
    const actions = useActions(actionsDefinition);
    const moveLeft = () => actions.move(axis.id, 'left');
    const moveRight = () => actions.move(axis.id, 'right');
    const stop = () => actions.move(axis.id, 'stop');
    return (_jsxs(HeaderCard, Object.assign({ className: "axis", header: _jsxs(Text, Object.assign({ t: Text.Type.H4 }, { children: [axis.deviceName, axis.name && _jsxs(_Fragment, { children: [" \u2192 ", axis.name] }, void 0)] }), void 0) }, { children: [axis.error && _jsx(NoticeBanner, { children: axis.error }, void 0), _jsx(Icons.LeftNormal, { title: "Move Left", appearsClickable: true, onMouseDown: moveLeft, onTouchStart: moveLeft, onTouchEnd: stop, onMouseUp: stop }, void 0), _jsx(Icons.RightNormal, { title: "Move Right", appearsClickable: true, onMouseDown: moveRight, onTouchStart: moveRight, onTouchEnd: stop, onMouseUp: stop }, void 0), _jsx("div", { className: "spacer" }, void 0), _jsxs("div", Object.assign({ className: "position" }, { children: [axis.position.toFixed(2), " ", axis.type === 'linear' ? 'mm' : 'deg'] }), void 0)] }), void 0));
};
