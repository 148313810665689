import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { ZaberApi } from '../app_components';
import { getContainer } from '../container';
import { environment } from '../environment';
import { selectRouter } from '../store';
import { ActionTypes, actions } from './actions';
import { selectFeedbackText, selectFeedbackEmail } from './selectors';
export function* feedbackSaga() {
    yield all([
        takeLatest(ActionTypes.SEND_FEEDBACK, sendFeedback),
    ]);
}
function* sendFeedback() {
    const email = yield select(selectFeedbackEmail);
    const text = yield select(selectFeedbackText);
    try {
        const api = getContainer().get(ZaberApi);
        const pathname = (yield select(selectRouter)).location.pathname;
        yield call([api, api.sendFeedback], {
            product: 'Zaber Cloud',
            version: environment.releaseName,
            comment: text,
            meta: { pathname, email }
        });
        yield put(actions.sendFeedbackDone());
    }
    catch (err) {
        yield put(actions.sendFeedbackDone(err.message));
    }
}
