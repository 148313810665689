import { all, fork } from 'redux-saga/effects';
import { userSaga } from '../user';
import { userAccessTokenSaga } from '../user_access_token';
import { virtualDeviceViewerSaga } from '../virtual_device_viewer';
import { virtualDeviceSaga } from '../virtual_device';
import { feedbackSaga } from '../feedback';
import { terminalSaga } from '../terminal';
import { demoSaga } from '../demo';
export function* rootSaga() {
    yield all([
        fork(userSaga),
        fork(userAccessTokenSaga),
        fork(virtualDeviceSaga),
        fork(virtualDeviceViewerSaga),
        fork(feedbackSaga),
        fork(terminalSaga),
        fork(demoSaga),
    ]);
}
