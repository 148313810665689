import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import * as Sentry from '@sentry/react';
import { ErrorReport } from './ErrorReport';
import { errorEmitter } from './errors';
export class ErrorHandler extends React.Component {
    constructor(props) {
        super(props);
        this.fallback = () => (_jsx(ErrorReport, {}, void 0));
        this.state = {};
        this.onError = this.onError.bind(this);
        errorEmitter.on('error', this.onError);
    }
    componentWillUnmount() {
        errorEmitter.off('error', this.onError);
    }
    onError() {
        this.setState({
            hasError: true,
        });
    }
    render() {
        const { hasError } = this.state;
        if (hasError) {
            return _jsx(ErrorReport, {}, void 0);
        }
        return (_jsx(Sentry.ErrorBoundary, Object.assign({ fallback: this.fallback }, { children: this.props.children }), void 0));
    }
}
