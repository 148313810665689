import { applyMiddleware, compose, createStore } from 'redux';
import reduxLogger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import storeFreeze from 'redux-freeze';
import { routerMiddleware } from 'connected-react-router';
import _ from 'lodash';
import { environment } from '../environment';
import { getLogger } from '../log';
import { handleUnexpectedError } from '../errors';
import { rootReducer } from './reducer';
import { rootSaga } from './saga';
import { browserHistory } from './history';
import { sentryBreadcrumb } from './sentry';
export const buildStore = (preloadedState) => {
    var _a, _b;
    const logger = getLogger('redux');
    const sagaMiddleware = createSagaMiddleware({
        onError: (err, info) => {
            handleUnexpectedError(err);
            logger.error(err, info);
        },
    });
    const middlewares = [
        sentryBreadcrumb,
        sagaMiddleware,
        routerMiddleware(browserHistory),
    ];
    if (environment.build === 'dev') {
        if (!environment.isTest) {
            middlewares.push(reduxLogger);
        }
        middlewares.push(storeFreeze);
    }
    const store = createStore(rootReducer, (preloadedState !== null && preloadedState !== void 0 ? preloadedState : {}), compose((_b = (_a = window.__REDUX_DEVTOOLS_EXTENSION__) === null || _a === void 0 ? void 0 : _a.call(window)) !== null && _b !== void 0 ? _b : _.identity, applyMiddleware(...middlewares)));
    sagaMiddleware.run(rootSaga);
    store.saga = sagaMiddleware;
    return store;
};
