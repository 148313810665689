import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { AnimationClasses, Button, EditableField, Icons, Text } from '@zaber/react-library';
import { useSelector } from 'react-redux';
import { selectCreatingSimulations, selectCollapsedPrivateSimulations, selectPrivateSimulations, selectSimulationsGettingRemoved, selectIsBuildingChain, } from '../virtual_device/selectors';
import { createSimulationViewerSimulationIdUrl } from '../urls';
import { VirtualDeviceOnOffToggle } from '../virtual_device/VirtualDeviceOnOffToggle';
import UnknownDeviceImage from '../assets/unknown_device.svg';
import { useActions } from '../utils';
import { virtualDeviceActionDefinitions } from '../virtual_device';
import { SimulationState } from '../virtual_device_api';
import { VirtualDeviceId } from '../virtual_device/VirtualDeviceId';
import { NewTabLink } from '../components/Links';
import { ChainBuilder } from './ChainBuilder';
export const OPEN_VIEWER_BUTTON_LABEL = '3D Viewer';
const Header = ({ expanded, isBeingRemoved, simulation, onExpandedToggle }) => {
    const actions = useActions(virtualDeviceActionDefinitions);
    return _jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "expander first heading-item" }, { children: [expanded && _jsx(Icons.ArrowExpanded, { appearsClickable: true, title: "Hide details", onClick: onExpandedToggle }, void 0), !expanded && _jsx(Icons.ArrowCollapsed, { appearsClickable: true, title: "Show details", onClick: onExpandedToggle }, void 0)] }), void 0), _jsxs("div", Object.assign({ className: "power-switch heading-item" }, { children: [_jsxs(Text, Object.assign({ e: Text.Emphasis.Bold }, { children: ["Devices (", simulation.components.length, ")"] }), void 0), _jsx(VirtualDeviceOnOffToggle, { disabled: isBeingRemoved, simulation: simulation }, void 0)] }), void 0), _jsx(EditableField, { className: "heading-item", value: simulation.name, disabled: simulation.state !== SimulationState.Off, validate: [
                    EditableField.validators.notEmpty,
                    EditableField.validators.maxLength(30),
                    EditableField.validators.notWhitespace,
                    EditableField.validators.noControl
                ], onValueChange: val => {
                    if (val !== simulation.name) {
                        actions.renameSimulation(simulation.id, val);
                    }
                } }, void 0), _jsxs("div", Object.assign({ className: "heading-item cloud-id" }, { children: [_jsx(Text, Object.assign({ e: Text.Emphasis.Bold }, { children: "ID:" }), void 0), _jsx(VirtualDeviceId, { disabled: isBeingRemoved, virtualDevice: simulation }, void 0)] }), void 0), _jsx("div", Object.assign({ className: "heading-item" }, { children: isBeingRemoved
                    ? _jsx(Button, Object.assign({ color: "grey", disabled: true }, { children: OPEN_VIEWER_BUTTON_LABEL }), void 0)
                    : _jsx(NewTabLink, Object.assign({ to: createSimulationViewerSimulationIdUrl(simulation.id) }, { children: _jsx(Button, Object.assign({ color: "grey" }, { children: OPEN_VIEWER_BUTTON_LABEL }), void 0) }), void 0) }), void 0), _jsxs("div", Object.assign({ className: "remove last heading-item" }, { children: [isBeingRemoved && _jsx(Icons.Gear, { className: AnimationClasses.Rotation }, void 0), !isBeingRemoved && _jsx(Icons.Trash, { title: "Remove Virtual Device", appearsClickable: true, onClick: () => actions.removeSimulation(simulation.id) }, void 0)] }), void 0)] }, void 0);
};
const Row = ({ component }) => _jsxs(_Fragment, { children: [_jsx("div", { className: "device-type first row-item" }, void 0), _jsxs("div", Object.assign({ className: "device-type product-image row-item" }, { children: [component.productImageUrl && _jsx("img", { className: "product-image", src: component.productImageUrl, alt: "Thumbnail" }, void 0), !component.productImageUrl && _jsx(UnknownDeviceImage, {}, void 0)] }), void 0), _jsx("div", Object.assign({ className: "device-type device-name row-item" }, { children: _jsx(Text, { children: component.productName }, void 0) }), void 0), _jsx("div", { className: "device-type row-item" }, void 0), _jsx("div", { className: "device-type last row-item" }, void 0)] }, void 0);
const ListItem = ({ simulation }) => {
    const actions = useActions(virtualDeviceActionDefinitions);
    const collapsed = useSelector(selectCollapsedPrivateSimulations);
    const simulationsGettingRemoved = useSelector(selectSimulationsGettingRemoved);
    const isGettingRemoved = simulationsGettingRemoved.includes(simulation.id);
    const isExpanded = !collapsed[simulation.id];
    return (_jsxs(React.Fragment, { children: [_jsx(Header, { expanded: isExpanded, onExpandedToggle: () => actions.togglePrivateSimulationExpanded(simulation.id), isBeingRemoved: isGettingRemoved, simulation: simulation }, void 0), isExpanded && simulation.components.map((component, i) => _jsx(Row, { component: component }, i))] }, simulation.id));
};
export const PrivateVirtualDeviceList = () => {
    const privateSimulations = useSelector(selectPrivateSimulations);
    const creatingSimulations = useSelector(selectCreatingSimulations);
    const isBuildingChain = useSelector(selectIsBuildingChain);
    return (_jsxs("div", Object.assign({ className: "device-list", "data-testid": "device-list" }, { children: [isBuildingChain && _jsx(ChainBuilder, {}, void 0), privateSimulations && privateSimulations.map(sim => (_jsx(ListItem, { simulation: sim }, sim.id))), creatingSimulations.map(sim => typeof sim === 'object' ? _jsx(ListItem, { simulation: sim }, sim.id) : null)] }), void 0));
};
