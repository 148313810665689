import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icons, MinorMenu, Text, CodeSnippetGroup } from '@zaber/react-library';
import classNames from 'classnames';
import { useState } from 'react';
import { match } from 'ts-pattern';
import dark from 'react-syntax-highlighter/dist/esm/styles/hljs/dark';
import { SimulationType } from '../../virtual_device_api';
import { NewTabLink } from '../../components/Links';
import { ExternalPageUrls, PageUrls } from '../../urls';
import { VirtualDeviceOnOffToggle } from '../../virtual_device/VirtualDeviceOnOffToggle';
import zmlVirtualDevicePyCode from './zml_setup.py.template';
import zmlVirtualDeviceCsCode from './zml_setup.cs.template';
import zmlVirtualDeviceJsCode from './zml_setup.js.template';
import zmlVirtualDeviceCppCode from './zml_setup.cpp.template';
import zmlVirtualDeviceJavaCode from './zml_setup.java.template';
import zmlVirtualDeviceMatlabCode from './zml_setup.matlab.template';
import zmlVirtualDeviceOctaveCode from './zml_setup.octave.template';
const zmlVDCode = {
    python: zmlVirtualDevicePyCode,
    javascript: zmlVirtualDeviceJsCode,
    csharp: zmlVirtualDeviceCsCode,
    cpp: zmlVirtualDeviceCppCode,
    java: zmlVirtualDeviceJavaCode,
    matlab: zmlVirtualDeviceMatlabCode,
    octave: zmlVirtualDeviceOctaveCode,
};
export const ViewerConnectionInstructions = ({ simulation, onClose }) => {
    const [selectedSoftware, setSelectedSoftware] = useState('launcher');
    const authenticated = simulation.type === SimulationType.Private;
    const codeSnippetData = {
        simulation,
        authenticated,
    };
    return (_jsxs("div", Object.assign({ className: "connection-instructions-container" }, { children: [_jsx(Text, Object.assign({ t: Text.Type.H3 }, { children: "Software Connection" }), void 0), _jsx(Icons.Cross, { onClick: onClose, interactionColor: "grey", title: "Close this panel" }, void 0), _jsx("br", {}, void 0), _jsxs(Text, { children: [_jsx("p", { children: "You can connect to this Virtual Device like any other cloud shared device using Zaber's software." }, void 0), _jsxs("p", { children: ["Follow the instructions below to connect with Zaber Launcher or Zaber Motion Library (ZML), or refer to the ", _jsx(NewTabLink, Object.assign({ to: PageUrls.ZmlVirtualDeviceHowTo }, { children: "ZML Virtual Device HOWTO" }), void 0), "."] }, void 0)] }, void 0), _jsxs(MinorMenu, Object.assign({ barStyle: "horizontal" }, { children: [_jsx("div", Object.assign({ className: classNames({ active: selectedSoftware === 'launcher' }), onClick: () => setSelectedSoftware('launcher') }, { children: _jsx(MinorMenu.Item, Object.assign({ "data-testid": "select-zl" }, { children: "Zaber Launcher" }), void 0) }), void 0), _jsx("div", Object.assign({ className: classNames({ active: selectedSoftware === 'zml' }), onClick: () => setSelectedSoftware('zml') }, { children: _jsx(MinorMenu.Item, Object.assign({ "data-testid": "select-zml" }, { children: "ZML" }), void 0) }), void 0)] }), void 0), match(selectedSoftware)
                .with('launcher', () => (_jsxs("ul", { children: [_jsxs("li", { children: [_jsx(Text, { children: "Make sure the Virtual Device is running" }, void 0), _jsx(VirtualDeviceOnOffToggle, { simulation: simulation }, void 0)] }, void 0), _jsxs("li", { children: [_jsx(NewTabLink, Object.assign({ to: PageUrls.ZaberLauncherDownloadPage }, { children: "Install Zaber Launcher" }), void 0), _jsx(Text, { children: " and open it" }, void 0)] }, void 0), authenticated && _jsx("li", { children: _jsx(Text, { children: "Go to the \"Cloud\" page and make sure you are signed in to your account" }, void 0) }, void 0), _jsx("li", { children: _jsx(Text, { children: "Go to the \"My Connections\" page and click on \"Add New Connection\"" }, void 0) }, void 0), _jsx("li", { children: _jsx(Text, { children: "Select \"Virtual Device (Early Access)\" and click \"Next Step\"" }, void 0) }, void 0), _jsx("li", { children: _jsx(Text, { children: "Copy the Virtual Device Cloud ID from the upper right of this page and paste it into Zaber Launcher" }, void 0) }, void 0), _jsx("li", { children: _jsx(Text, { children: "Click on \"Create Connection\" to finish connecting" }, void 0) }, void 0), authenticated && _jsx("li", { children: _jsx(Text, { children: "Alternatively, you can click on \"Shared with Me\" on the \"Cloud\" page after signing in, and your Virtual Device will be automatically discovered; you will then be able to add it to your connections" }, void 0) }, void 0), _jsx("br", {}, void 0), _jsx("br", {}, void 0)] }, void 0)))
                .with('zml', () => (_jsxs("ul", { children: [_jsxs("li", { children: [_jsx(Text, { children: "Make sure the Virtual Device is running" }, void 0), _jsx(VirtualDeviceOnOffToggle, { simulation: simulation }, void 0)] }, void 0), authenticated && _jsxs("li", { children: [_jsx(NewTabLink, Object.assign({ to: PageUrls.AuthAccessTokens }, { children: "Obtain an access token" }), void 0), _jsx(Text, { children: " if you don't already have one" }, void 0)] }, void 0), _jsxs("li", { children: [_jsx(Text, { children: "Setup Zaber Motion Library on your computer by following the " }, void 0), _jsx(NewTabLink, Object.assign({ to: ExternalPageUrls.ZaberZMLGettingStarted }, { children: "Getting Started guide for your preferred language" }), void 0)] }, void 0), _jsxs("li", { children: [_jsx(Text, { children: "Use the following code snippet for your preferred language to connect to the virtual device from your application. The Cloud ID for the Virtual Device is already present in the code snippet below." }, void 0), _jsx(CodeSnippetGroup, { templates: zmlVDCode, templateData: codeSnippetData, colorScheme: dark }, void 0)] }, void 0), _jsx("br", {}, void 0), _jsx("br", {}, void 0)] }, void 0)))
                .exhaustive()] }), void 0));
};
