import { all, takeLatest, put, select, take, takeEvery, fork, call, delay, race } from 'redux-saga/effects';
import { Angle, ascii, Length } from '@zaber/motion';
import { getContainer } from '../container';
import { VirtualDevicesService } from '../virtual_device_connection';
import { ActionTypes, actions } from './actions';
import { selectRouterId, selectLastAxisId } from './selectors';
export function* demoSaga() {
    yield all([
        takeLatest(ActionTypes.MOUNT, mount),
    ]);
}
function* mount() {
    const routerId = yield select(selectRouterId);
    if (routerId == null) {
        return;
    }
    const service = getContainer().get(VirtualDevicesService);
    try {
        const router = yield service.getConnection(routerId, 'unauthenticated');
        const connections = yield router.api.getConnections();
        for (const connection of connections) {
            yield fork(startConnection, connection.id, router);
        }
    }
    catch (err) {
        yield put(actions.addError(String(err)));
    }
    yield put(actions.changeLoadingCounter(-1));
}
function* startConnection(id, router) {
    let connection;
    try {
        yield put(actions.changeLoadingCounter(1));
        connection = yield router.getStandaloneRoutedConnection(id);
        const devices = yield connection.detectDevices();
        for (const device of devices) {
            const { axisCount } = device.identity;
            for (let axisNumber = 1; axisNumber <= axisCount; axisNumber++) {
                const axis = device.getAxis(axisNumber);
                yield fork(startAxis, axis);
            }
        }
        yield put(actions.changeLoadingCounter(-1));
        yield take(ActionTypes.UNMOUNT);
    }
    catch (err) {
        yield put(actions.addError(String(err)));
        yield put(actions.changeLoadingCounter(-1));
    }
    finally {
        if (connection) {
            yield connection.close();
        }
    }
}
function* startAxis(axis) {
    const { identity, device: { identity: deviceIdentity } } = axis;
    if (![ascii.AxisType.LINEAR, ascii.AxisType.ROTARY].includes(identity.axisType)) {
        return;
    }
    const axisData = {
        name: identity.peripheralName,
        deviceName: deviceIdentity.name,
        type: identity.axisType === ascii.AxisType.LINEAR ? 'linear' : 'rotary',
    };
    try {
        yield put(actions.changeLoadingCounter(1));
        axisData.maxSpeed = yield axis.settings.get('maxspeed');
        const warnings = yield axis.warnings.clearFlags();
        if (warnings.has(ascii.WarningFlags.NO_REFERENCE_POSITION)) {
            yield axis.home();
        }
    }
    catch (err) {
        yield put(actions.addError(String(err)));
        return;
    }
    finally {
        yield put(actions.changeLoadingCounter(-1));
    }
    yield put(actions.addAxis(axisData));
    const id = yield select(selectLastAxisId);
    const ctx = {
        id,
        axis,
        axisData: axisData,
    };
    yield all([
        takeEvery((a) => { var _a; return a.type === ActionTypes.MOVE && ((_a = a.payload) === null || _a === void 0 ? void 0 : _a.id) === id; }, moveAxis, ctx),
        call(monitorAxis, ctx),
    ]);
}
function* moveAxis({ axis, axisData, id }, { payload: { moveType } }) {
    try {
        switch (moveType) {
            case 'left':
                yield axis.moveVelocity(-axisData.maxSpeed);
                yield axis.waitUntilIdle();
                break;
            case 'right':
                yield axis.moveVelocity(axisData.maxSpeed);
                yield axis.waitUntilIdle();
                break;
            case 'stop':
                yield axis.stop();
                break;
        }
    }
    catch (err) {
        yield put(actions.setError(id, String(err)));
    }
    yield put(actions.refreshPosition(id));
}
function* monitorAxis({ axis, id, axisData }) {
    try {
        const units = axisData.type === 'linear' ? Length.mm : Angle.DEGREES;
        for (;;) {
            const position = yield axis.settings.get('pos', units);
            yield put(actions.updatePosition(id, position));
            yield race([
                delay(1000),
                take((a) => { var _a; return a.type === ActionTypes.REFRESH_POSITION && ((_a = a.payload) === null || _a === void 0 ? void 0 : _a.id) === id; }),
            ]);
        }
    }
    catch (err) {
        yield put(actions.setError(id, String(err)));
    }
}
