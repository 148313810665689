var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@zaber/react-library';
import copy from 'copy-to-clipboard';
import { useSelector } from 'react-redux';
import { selectTerminalHistory } from './selectors';
export const CopyAllHistoryButton = (_a) => {
    var { simulationId, onClick } = _a, rest = __rest(_a, ["simulationId", "onClick"]);
    const history = useSelector(selectTerminalHistory);
    return _jsx(Button, Object.assign({ title: "Copy Command History to Clipboard", onClick: e => {
            history[simulationId] && copy(history[simulationId].map(item => item.text).join('\n'));
            if (onClick && onClick !== 'disabled') {
                onClick === null || onClick === void 0 ? void 0 : onClick(e);
            }
        } }, rest), void 0);
};
