import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { AnimationClasses, Button, Icons, Text } from '@zaber/react-library';
import { useSelector } from 'react-redux';
import { NavigationBar } from '../navigation_bar';
import { selectCreatingSimulations, selectIsBuildingChain, selectPrivateSimulations, } from '../virtual_device/selectors';
import NoDevicesFoundImage from '../assets/no_vd_in_list.svg';
import { Footer } from '../components/Footer';
import { PageUrls } from '../urls';
import { useActions } from '../utils';
import { virtualDeviceActionDefinitions } from '../virtual_device';
import { PageTitle } from '../components/PageTitle';
import { Survey } from '../components/Survey';
import { useLoadPrivateSimulations, useLoadSimulationProducts } from '../virtual_device/effects';
import { PrivateVirtualDeviceList } from './PrivateVirtualDeviceList';
import { DeviceManagerErrors } from './DeviceManagerErrors';
export const VirtualDeviceManager = () => {
    const actions = useActions(virtualDeviceActionDefinitions);
    const creatingSimulations = useSelector(selectCreatingSimulations);
    const isBuildingChain = useSelector(selectIsBuildingChain);
    const privateSimulations = useSelector(selectPrivateSimulations);
    const privateSimulationCount = (privateSimulations === null || privateSimulations === void 0 ? void 0 : privateSimulations.length) || 0;
    useLoadSimulationProducts();
    const loadingSimulationsState = useLoadPrivateSimulations();
    const loadingSimulations = (loadingSimulationsState === null || loadingSimulationsState === void 0 ? void 0 : loadingSimulationsState.state) === 'loading';
    useEffect(() => {
        actions.startMonitoring();
        return () => {
            actions.resetSimulationCreationError();
            actions.stopMonitoring();
        };
    }, []);
    return (_jsxs("div", Object.assign({ className: "virtual-device-manager page" }, { children: [_jsx(PageTitle, { children: "My Virtual Devices" }, void 0), _jsx(NavigationBar, {}, void 0), _jsxs("div", Object.assign({ className: "content" }, { children: [_jsxs("div", Object.assign({ className: "page-title-container" }, { children: [_jsxs("div", { children: [_jsx(Text, Object.assign({ className: "page-title", t: Text.Type.H3, e: Text.Emphasis.Light }, { children: "My Virtual Devices" }), void 0), !loadingSimulations &&
                                        _jsx(Button, Object.assign({ className: "create-chain", disabled: isBuildingChain, onClick: () => {
                                                actions.chainBuilderStart();
                                                actions.chainBuilderAddDevice();
                                            } }, { children: "Create Device Chain" }), void 0)] }, void 0), _jsx("div", Object.assign({ className: "padding" }, { children: "\u00A0" }), void 0), _jsx(Survey, { emphasis: Text.Emphasis.Light }, void 0)] }), void 0), loadingSimulations && _jsxs("div", Object.assign({ className: "loading-devices" }, { children: [_jsx(Icons.Gear, { className: AnimationClasses.Rotation }, void 0), _jsx(Text, Object.assign({ t: Text.Type.H3, e: Text.Emphasis.Light }, { children: "Loading..." }), void 0)] }), void 0), !loadingSimulations && privateSimulationCount === 0 &&
                        creatingSimulations.length === 0 && !isBuildingChain && _jsxs("div", Object.assign({ className: "no-devices-found" }, { children: [_jsx(NoDevicesFoundImage, { className: "no-devices-found-image" }, void 0), _jsx(Text, Object.assign({ t: Text.Type.H4 }, { children: "You currently have no virtual devices." }), void 0)] }), void 0), !loadingSimulations && (privateSimulationCount > 0 || creatingSimulations.length > 0 || isBuildingChain) &&
                        _jsx("div", Object.assign({ className: "devices-found" }, { children: _jsx(PrivateVirtualDeviceList, {}, void 0) }), void 0), _jsx(DeviceManagerErrors, {}, void 0)] }), void 0), _jsx(Footer, { releaseNotesUrl: PageUrls.VirtualDeviceReleaseNotes }, void 0)] }), void 0));
};
