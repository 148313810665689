var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { all, call, put, select, takeLeading, fork, take, takeEvery } from 'redux-saga/effects';
import { Auth } from '@aws-amplify/auth';
import { routerActions } from 'connected-react-router';
import { getContainer } from '../container';
import { throwIfJsError } from '../utils';
import { environment } from '../environment';
import { PageUrls } from '../urls';
import { AwsCredentialsService, getMessageFromError, Log, ZaberApi } from '../app_components';
import { selectAuthState, selectLoginData, selectSignUpData } from './selectors';
import { actions, ActionTypes } from './actions';
import { AuthState } from './types';
let logger;
export function* userSaga() {
    logger = getContainer().get(Log).getLogger('userSaga');
    yield all([
        takeLeading(ActionTypes.SIGN_UP, signUp),
        takeLeading(ActionTypes.RESEND_SIGN_UP_EMAIL, resendSignUpEmail),
        takeLeading(ActionTypes.LOGIN, login),
        takeLeading(ActionTypes.LOGOUT, logout),
        takeEvery(ActionTypes.LOGIN_RESULT, onLoginResult),
        takeEvery(ActionTypes.CHECK_AUTH_RESULT, onCheckAuthResult),
        takeEvery(ActionTypes.USER_ATTRIBUTES_UPDATE, updateUserData),
        takeEvery(ActionTypes.USER_PASSWORD_CHANGE, changeUserPassword),
        takeEvery(ActionTypes.PASSWORD_RESET_REQUEST, requestPasswordReset),
        takeEvery(ActionTypes.PASSWORD_RESET_SUBMIT, submitPasswordReset),
        takeEvery(ActionTypes.USER_ACCOUNT_DELETE, deleteUserAccount),
        ...(environment.isTest ? [] : [fork(checkLoggedIn)]),
    ]);
}
function* signUp() {
    const signUpData = yield select(selectSignUpData);
    try {
        const result = yield call([Auth, Auth.signUp], {
            username: signUpData.email,
            password: signUpData.password,
        });
        yield put(actions.signUpResult());
        logger.info('Signed up', result.userSub);
    }
    catch (err) {
        throwIfJsError(err);
        logger.warn(err);
        // TODO: handle common errors, parse code
        yield put(actions.signUpResult(err.message || String(err)));
    }
}
export function* resendSignUpEmail({ payload: { email } }) {
    try {
        yield call([Auth, Auth.resendSignUp], email);
        yield put(actions.resendSignUpEmailDone());
    }
    catch (err) {
        throwIfJsError(err);
        yield put(actions.resendSignUpEmailDone(err.message || String(err)));
    }
}
function setAwsCredentials(user) {
    return __awaiter(this, void 0, void 0, function* () {
        const awsCredentials = getContainer().get(AwsCredentialsService);
        yield awsCredentials.setUser(user !== null && user !== void 0 ? user : 'unauthenticated');
    });
}
function* login() {
    const loginData = yield select(selectLoginData);
    try {
        if (!loginData.email) {
            throw new Error('Email not provided.');
        }
        if (!loginData.password) {
            throw new Error('Password not provided.');
        }
        const user = yield call([Auth, Auth.signIn], {
            username: loginData.email,
            password: loginData.password,
        });
        const loggedUser = yield call(getUserData, user);
        yield put(actions.loginResult(loggedUser));
        yield call(setAwsCredentials, user);
        logger.info('Logged in', loggedUser.sub);
    }
    catch (err) {
        throwIfJsError(err);
        logger.warn(err);
        yield put(actions.loginResultErr(err.message || String(err)));
    }
}
function* checkLoggedIn() {
    try {
        const user = yield call([Auth, Auth.currentAuthenticatedUser]);
        const loggedUser = yield call(getUserData, user);
        yield put(actions.checkAuthResult(loggedUser));
        yield call(setAwsCredentials, user);
        logger.info('Logged in', loggedUser.sub);
    }
    catch (err) {
        throwIfJsError(err);
        logger.warn(err);
        yield call(setAwsCredentials);
        yield put(actions.checkAuthResult());
    }
}
export function getUserData(user) {
    return __awaiter(this, void 0, void 0, function* () {
        const attributes = yield Auth.userAttributes(user);
        const attributesDict = attributes.reduce((rest, attribute) => (Object.assign(Object.assign({}, rest), { [attribute.Name]: attribute.Value })), {});
        return attributesDict;
    });
}
function* logout() {
    try {
        yield call(setAwsCredentials);
        yield call([Auth, Auth.signOut]);
        yield put(actions.authChanged(false, false));
    }
    catch (err) {
        throwIfJsError(err);
        logger.warn(err);
    }
}
function* onCheckAuthResult({ payload: { loggedInUser } }) {
    yield put(actions.authChanged(!!loggedInUser, true));
}
function* onLoginResult({ payload: { loggedInUser } }) {
    yield put(actions.authChanged(!!loggedInUser, false));
}
export function* userIsLoggedIn() {
    let authState = yield select(selectAuthState);
    if (authState === AuthState.Checking) {
        yield take(ActionTypes.CHECK_AUTH_RESULT);
    }
    authState = yield select(selectAuthState);
    return authState === AuthState.Authenticated;
}
function* requestPasswordReset({ payload: { email } }) {
    try {
        yield call([Auth, Auth.forgotPassword], email);
        yield put(actions.passwordResetRequestDone());
    }
    catch (e) {
        throwIfJsError(e);
        yield put(actions.passwordResetRequestDone(getMessageFromError(e)));
    }
}
function* submitPasswordReset({ payload: { email, resetCode, newPassword } }) {
    try {
        yield call([Auth, Auth.forgotPasswordSubmit], email, resetCode.trim(), newPassword);
        yield put(actions.passwordResetSubmitDone());
        yield put(routerActions.replace(PageUrls.Login));
    }
    catch (e) {
        throwIfJsError(e);
        yield put(actions.passwordResetSubmitDone(getMessageFromError(e)));
    }
}
function* updateUserData({ payload: { userDataToUpdate } }) {
    try {
        const user = yield call([Auth, Auth.currentAuthenticatedUser]);
        yield call([Auth, Auth.updateUserAttributes], user, userDataToUpdate);
        const loggedUser = yield call(getUserData, user);
        yield put(actions.userAttributesUpdated(loggedUser));
    }
    catch (e) {
        throwIfJsError(e);
        yield put(actions.userAttributesUpdateError(getMessageFromError(e)));
    }
}
function* changeUserPassword({ payload: { oldPassword, newPassword } }) {
    try {
        const user = yield call([Auth, Auth.currentAuthenticatedUser]);
        yield call([Auth, Auth.changePassword], user, oldPassword, newPassword);
        yield put(actions.userPasswordChanged());
    }
    catch (e) {
        throwIfJsError(e);
        yield put(actions.userPasswordChangeError(getMessageFromError(e)));
    }
}
function* deleteUserAccount({ payload: { email, password } }) {
    const zaberApi = getContainer().get(ZaberApi);
    try {
        const user = yield call([Auth, Auth.signIn], {
            username: email,
            password,
        });
        try {
            // We don't need to abort the operation if this fails; it just means some back-end resources may be leaked.
            yield call([zaberApi, zaberApi.cleanupDeletedUserVirtualDevices]);
        }
        catch (e) {
            throwIfJsError(e);
            logger.error('API call to clean up deleted user\'s Virtual Devices may have failed.', e);
        }
        yield call(() => new Promise((resolve, reject) => user.deleteUser((error, res) => error ? reject(error) : resolve(res))));
        yield put(actions.userAccountDeleted());
        yield put(actions.logout());
    }
    catch (e) {
        throwIfJsError(e);
        yield put(actions.userAccountDeleteError(getMessageFromError(e)));
    }
}
