import { createSelector } from 'reselect';
import { selectUser } from '../store';
import { pick, nullGuard } from '../utils';
import { AuthState, OptionalAttributes, CustomAttributes } from './types';
export const selectSignUpData = createSelector(selectUser, state => state.signUpData);
export const selectSignUpInProgress = createSelector(selectUser, state => state.signUpInProgress);
export const selectSignUpError = createSelector(selectUser, state => state.signUpError);
export const selectLoginData = createSelector(selectUser, state => state.loginData);
export const selectLoginInProgress = createSelector(selectUser, state => state.loginInProgress);
export const selectLoginError = createSelector(selectUser, state => state.loginError);
export const selectAuthState = createSelector(selectUser, state => state.authState);
export const selectUserIsLoggedIn = createSelector(selectUser, state => state.authState === AuthState.Authenticated);
export const selectLoggedInUserData = createSelector(selectUser, state => state.loggedInUser);
export const selectLoggedInUserEmail = createSelector(selectLoggedInUserData, data => data === null || data === void 0 ? void 0 : data.email);
export const selectLoggedInUserFirstName = createSelector(selectLoggedInUserData, data => data === null || data === void 0 ? void 0 : data[OptionalAttributes.FirstName]);
export const selectLoggedInUserLastName = createSelector(selectLoggedInUserData, data => data === null || data === void 0 ? void 0 : data[OptionalAttributes.LastName]);
export const selectResendSignUpEmail = createSelector(selectUser, state => pick(state, 'resendSignUpEmailInProgress', 'resendSignUpEmailError'));
export const selectPasswordReset = createSelector(selectUser, state => pick(state, 'passwordResetRequestInProgress', 'passwordResetSubmitInProgress', 'passwordResetError'));
export const selectUserAttributes = createSelector(selectUser, state => pick(state, 'userAttributesUpdating', 'userAttributesUpdateError'));
export const selectUserPassword = createSelector(selectUser, state => pick(state, 'userPasswordChanging', 'userPasswordChangeError'));
export const selectUserAccountDelete = createSelector(selectUser, state => pick(state, 'accountDeleteInProgress', 'accountDeleteError'));
export const selectIotRealm = createSelector(selectUser, nullGuard(user => { var _a; return (_a = user.loggedInUser) === null || _a === void 0 ? void 0 : _a[CustomAttributes.IdentityId]; }));
