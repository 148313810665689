import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Text, Icons, AnimationClasses, Chevron } from '@zaber/react-library';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useActions } from '../utils';
import { actions as actionDefinitions } from './actions';
import { selectAccessTokenDeletionErrors, selectAccessTokens, selectAccessTokensLoading, selectTokenIdSelectedForDeletion } from './selectors';
import { AVAILABLE_ACCESS_TOKEN_CREATED_BY_READABLE_NAME, ACCESS_TOKEN_SCOPES_DISPLAY_DATA } from './types';
import { TokenDeletionPopup } from './TokenDeletionPopup';
function formatDateTime(date) {
    return moment(date).format('lll');
}
export const AccessTokenList = ({ createdBy }) => {
    const actions = useActions(actionDefinitions);
    const tokensLoading = useSelector(selectAccessTokensLoading);
    const tokens = useSelector(selectAccessTokens);
    const tokenIdSelectedForDeletion = useSelector(selectTokenIdSelectedForDeletion);
    const tokenDeletionErrors = useSelector(selectAccessTokenDeletionErrors);
    const [showTable, setShowTable] = useState(createdBy ? false : true);
    const tokensCreatedByTarget = tokens === null || tokens === void 0 ? void 0 : tokens.filter(token => token.createdBy === createdBy);
    useEffect(() => {
        actions.loadAccessTokens();
    }, []);
    if (!tokensCreatedByTarget || tokensCreatedByTarget.length === 0) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "existing-tokens-title" }, { children: [_jsx(Chevron, { expanded: showTable, onClick: () => setShowTable(!showTable) }, void 0), _jsx(Text, Object.assign({ t: Text.Type.H4 }, { children: createdBy ?
                            `Access Tokens Used By ${AVAILABLE_ACCESS_TOKEN_CREATED_BY_READABLE_NAME[createdBy]}` : 'Your Access Tokens' }), void 0), _jsx(Icons.Refresh, { className: "refresh-icon", appearsClickable: true, onClick: () => actions.loadAccessTokens() }, void 0)] }), void 0), showTable && _jsxs(_Fragment, { children: [tokensLoading && _jsx(Icons.Gear, { className: AnimationClasses.Rotation }, void 0), tokensCreatedByTarget && tokensCreatedByTarget.length > 0 && _jsx("table", Object.assign({ className: "existing-tokens" }, { children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("th", { children: _jsx(Text, Object.assign({ e: Text.Emphasis.Bold }, { children: "Name" }), void 0) }, void 0), _jsx("th", { children: _jsx(Text, Object.assign({ e: Text.Emphasis.Bold }, { children: "Created At" }), void 0) }, void 0), _jsx("th", { children: _jsx(Text, Object.assign({ e: Text.Emphasis.Bold }, { children: "Last Used At" }), void 0) }, void 0), _jsx("th", { children: _jsx(Text, Object.assign({ e: Text.Emphasis.Bold }, { children: "Last Used By" }), void 0) }, void 0), _jsx("th", { children: _jsx(Text, Object.assign({ e: Text.Emphasis.Bold }, { children: "Expires" }), void 0) }, void 0), _jsx("th", { children: _jsx(Text, Object.assign({ e: Text.Emphasis.Bold }, { children: "Scopes" }), void 0) }, void 0), _jsx("th", { children: _jsx(Text, Object.assign({ e: Text.Emphasis.Bold }, { children: "Delete" }), void 0) }, void 0)] }, void 0), tokensCreatedByTarget.map(token => {
                                    var _a;
                                    return _jsxs("tr", { children: [_jsx("td", Object.assign({ className: "text-column" }, { children: _jsx(Text, { children: token.description }, void 0) }), void 0), _jsx("td", { children: _jsx(Text, { children: formatDateTime(token.createdAt) }, void 0) }, void 0), _jsx("td", { children: _jsx(Text, { children: token.lastUsedAt && formatDateTime(token.lastUsedAt) }, void 0) }, void 0), _jsx("td", Object.assign({ className: "text-column" }, { children: _jsx(Text, { children: token.lastUsedBy }, void 0) }), void 0), _jsx("td", { children: _jsx(Text, { children: token.expiry && formatDateTime(token.expiry) }, void 0) }, void 0), _jsx("td", Object.assign({ className: "text-column" }, { children: _jsx(Text, { children: (_a = token.scopes) === null || _a === void 0 ? void 0 : _a.map(scope => ACCESS_TOKEN_SCOPES_DISPLAY_DATA[scope].title).join(', ') }, void 0) }), void 0), _jsxs("td", { children: [_jsx(Icons.Trash, { appearsClickable: true, disabled: tokenIdSelectedForDeletion !== null, onClick: () => actions.chooseTokenForDeletion(token.tokenId), "data-testid": "delete-icon" }, void 0), tokenIdSelectedForDeletion === token.tokenId
                                                        && _jsx(TokenDeletionPopup, { error: tokenDeletionErrors[token.tokenId], tokenId: tokenIdSelectedForDeletion, tokenName: token.description }, void 0)] }, void 0)] }, token.tokenId);
                                })] }, void 0) }), void 0)] }, void 0)] }, void 0));
};
