import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { selectCurrentSimulation } from './selectors';
import { ViewerMessageOverlay } from './ViewerMessageOverlay';
export const ViewerSimulationError = () => {
    const currentSimulation = useSelector(selectCurrentSimulation);
    if (currentSimulation === null || currentSimulation === void 0 ? void 0 : currentSimulation.error) {
        return _jsx(ViewerMessageOverlay, Object.assign({ type: "error", title: "An error has occurred" }, { children: currentSimulation.error }), void 0);
    }
    return null;
};
