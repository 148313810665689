var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { selectTerminalHistory } from './selectors';
import { TerminalHistoryItem } from './TerminalHistoryItem';
export const TerminalHistory = (_a) => {
    var _b;
    var { simulationId, autoScrollToBottom = true } = _a, rest = __rest(_a, ["simulationId", "autoScrollToBottom"]);
    const history = useSelector(selectTerminalHistory);
    const scrollToBottomRef = useRef(null);
    const simulationHistory = history[simulationId];
    useEffect(() => {
        var _a;
        if (autoScrollToBottom) {
            (_a = scrollToBottomRef === null || scrollToBottomRef === void 0 ? void 0 : scrollToBottomRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ block: 'end' });
        }
    }, [autoScrollToBottom, (_b = _.last(history[simulationId])) === null || _b === void 0 ? void 0 : _b.key]);
    return (_jsxs("div", Object.assign({}, rest, { children: [simulationHistory && simulationHistory.map(historyItem => (_jsx(TerminalHistoryItem, { historyItem: historyItem }, historyItem.key))), _jsx("div", { ref: scrollToBottomRef }, void 0)] }), void 0));
};
