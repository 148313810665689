import { createReducer } from '../utils';
import { ActionTypes } from './actions';
import { AuthState, EMAIL_NOT_CONFIRMED_ERROR } from './types';
const defaultSignUpData = {
    email: '',
    password: '',
    passwordAgain: '',
};
const defaultLoginData = {
    email: '',
    password: '',
};
const initialState = {
    signUpData: defaultSignUpData,
    signUpError: null,
    signUpInProgress: false,
    resendSignUpEmailInProgress: false,
    resendSignUpEmailError: null,
    authState: AuthState.Checking,
    loggedInUser: null,
    loginData: defaultLoginData,
    loginError: null,
    loginInProgress: false,
    userAttributesUpdating: false,
    userAttributesUpdateError: null,
    userPasswordChanging: false,
    userPasswordChangeError: null,
    passwordResetRequestInProgress: false,
    passwordResetSubmitInProgress: false,
    passwordResetError: null,
    accountDeleteInProgress: false,
    accountDeleteError: null,
};
const updateSignUpData = (state, data) => (Object.assign(Object.assign({}, state), { signUpData: Object.assign(Object.assign({}, state.signUpData), data) }));
const signUp = (state) => (Object.assign(Object.assign({}, state), { signUpInProgress: true, signUpError: null }));
const signUpResult = (state, { error }) => (Object.assign(Object.assign({}, state), { signUpInProgress: false, signUpError: error !== null && error !== void 0 ? error : null, signUpData: !error
        ? Object.assign(Object.assign({}, defaultSignUpData), { email: state.signUpData.email }) : state.signUpData }));
const resendSignUpEmail = (state) => (Object.assign(Object.assign({}, state), { resendSignUpEmailInProgress: true, resendSignUpEmailError: null }));
const resendSignUpEmailDone = (state, { error }) => (Object.assign(Object.assign({}, state), { resendSignUpEmailInProgress: false, resendSignUpEmailError: error !== null && error !== void 0 ? error : null }));
const login = (state) => (Object.assign(Object.assign({}, state), { loginInProgress: true, loginError: null }));
const loginResult = (state, { error, loggedInUser }) => (Object.assign(Object.assign(Object.assign({}, state), { loginInProgress: false }), (!error ? {
    loginError: null,
    loginData: defaultLoginData,
    authState: AuthState.Authenticated,
    loggedInUser: loggedInUser !== null && loggedInUser !== void 0 ? loggedInUser : null,
} : {
    loginError: error !== null && error !== void 0 ? error : null,
    loginData: Object.assign(Object.assign({}, state.loginData), { password: '' }),
})));
const updateLoginData = (state, data) => (Object.assign(Object.assign({}, state), { loginData: Object.assign(Object.assign({}, state.loginData), data), loginError: state.loginError !== EMAIL_NOT_CONFIRMED_ERROR || !data.email || state.loginData.email === data.email
        ? state.loginError
        : null }));
const checkAuthStateResult = (state, { loggedInUser }) => (Object.assign(Object.assign({}, state), { authState: loggedInUser ? AuthState.Authenticated : AuthState.NotAuthenticated, loggedInUser: loggedInUser !== null && loggedInUser !== void 0 ? loggedInUser : null }));
const logout = (state) => (Object.assign(Object.assign({}, state), { authState: AuthState.NotAuthenticated, loggedInUser: null }));
const passwordResetRequest = (state) => (Object.assign(Object.assign({}, state), { passwordResetRequestInProgress: true, passwordResetError: null }));
const passwordResetRequestDone = (state, { error }) => (Object.assign(Object.assign({}, state), { passwordResetRequestInProgress: false, passwordResetError: error !== null && error !== void 0 ? error : null }));
const passwordResetSubmit = (state) => (Object.assign(Object.assign({}, state), { passwordResetSubmitInProgress: true, passwordResetError: null }));
const passwordResetSubmitDone = (state, { error }) => (Object.assign(Object.assign({}, state), { passwordResetSubmitInProgress: false, passwordResetError: error !== null && error !== void 0 ? error : null }));
const passwordResetClear = (state) => (Object.assign(Object.assign({}, state), { passwordResetRequestInProgress: false, passwordResetSubmitInProgress: false, passwordResetError: null }));
const userAttributesUpdate = (state) => (Object.assign(Object.assign({}, state), { userAttributesUpdating: true, userAttributesUpdateError: null }));
const userAttributesUpdated = (state, { userData, error }) => (Object.assign(Object.assign({}, state), { userAttributesUpdating: false, loggedInUser: userData == null ? state.loggedInUser : userData, userAttributesUpdateError: error !== null && error !== void 0 ? error : null }));
const userPasswordChange = (state) => (Object.assign(Object.assign({}, state), { userPasswordChanging: true, userPasswordChangeError: null }));
const userPasswordChanged = (state, { error }) => (Object.assign(Object.assign({}, state), { userPasswordChanging: false, userPasswordChangeError: error !== null && error !== void 0 ? error : null }));
const userAccountDelete = (state) => (Object.assign(Object.assign({}, state), { accountDeleteInProgress: true, accountDeleteError: null }));
const userAccountDeleted = (state, { error }) => (Object.assign(Object.assign({}, state), { accountDeleteInProgress: false, accountDeleteError: error !== null && error !== void 0 ? error : null }));
const resetErrors = (state) => (Object.assign(Object.assign({}, state), { loginError: null, signUpError: null, accountDeleteError: null, passwordResetError: null, userPasswordChangeError: null, userAttributesUpdateError: null, resendSignUpEmailError: null }));
export const reducer = createReducer({
    [ActionTypes.UPDATE_SIGN_UP_DATA]: updateSignUpData,
    [ActionTypes.SIGN_UP]: signUp,
    [ActionTypes.SIGN_UP_RESULT]: signUpResult,
    [ActionTypes.RESEND_SIGN_UP_EMAIL]: resendSignUpEmail,
    [ActionTypes.RESEND_SIGN_UP_EMAIL_DONE]: resendSignUpEmailDone,
    [ActionTypes.LOGIN]: login,
    [ActionTypes.LOGIN_RESULT]: loginResult,
    [ActionTypes.UPDATE_LOGIN_DATA]: updateLoginData,
    [ActionTypes.CHECK_AUTH_RESULT]: checkAuthStateResult,
    [ActionTypes.LOGOUT]: logout,
    [ActionTypes.PASSWORD_RESET_REQUEST]: passwordResetRequest,
    [ActionTypes.PASSWORD_RESET_REQUEST_DONE]: passwordResetRequestDone,
    [ActionTypes.PASSWORD_RESET_SUBMIT]: passwordResetSubmit,
    [ActionTypes.PASSWORD_RESET_SUBMIT_DONE]: passwordResetSubmitDone,
    [ActionTypes.PASSWORD_RESET_CLEAR]: passwordResetClear,
    [ActionTypes.USER_ATTRIBUTES_UPDATE]: userAttributesUpdate,
    [ActionTypes.USER_ATTRIBUTES_UPDATED]: userAttributesUpdated,
    [ActionTypes.USER_PASSWORD_CHANGE]: userPasswordChange,
    [ActionTypes.USER_PASSWORD_CHANGED]: userPasswordChanged,
    [ActionTypes.USER_ACCOUNT_DELETE]: userAccountDelete,
    [ActionTypes.USER_ACCOUNT_DELETED]: userAccountDeleted,
    [ActionTypes.RESET_ERRORS]: resetErrors,
}, initialState);
