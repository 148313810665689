import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Text, LinkClasses } from '@zaber/react-library';
import { Link } from 'react-router-dom';
import { ExternalPageUrls, PageUrls } from '../urls';
import { FeedbackLink } from '../feedback';
import { NewTabLink, SameTabLink } from '../components/Links';
export const content = [
    {
        header: 'Conditions of Use',
        body: _jsx("p", { children: _jsx(Text, { children: "The services and software products we provide are subject to the conditions stated below in this document. Every time you visit this website or use our software, you accept the following conditions. This is why we urge you to read them carefully." }, void 0) }, void 0),
    },
    {
        header: 'Privacy Policy',
        body: _jsx("p", { children: _jsxs(Text, { children: ["Before you continue using our website or software we advise you to read our\u00A0", _jsx(Link, Object.assign({ to: PageUrls.PrivacyPolicy, className: LinkClasses.Default }, { children: "privacy policy" }), void 0), "."] }, void 0) }, void 0)
    },
    {
        header: 'Copyright',
        body: _jsxs(_Fragment, { children: [_jsx("p", { children: _jsx(Text, { children: "Content published on this website, including images, text, video and software, is the property of Zaber Technologies Inc. and protected by international copyright laws." }, void 0) }, void 0), _jsx("p", { children: _jsxs(Text, { children: ["Most Zaber software is open-source and can be found either at\u00A0", _jsx(NewTabLink, Object.assign({ to: ExternalPageUrls.ZaberGitlab, className: LinkClasses.Default }, { children: "GitLab" }), void 0), "\u00A0or on our ", _jsx(SameTabLink, Object.assign({ to: ExternalPageUrls.ZaberSoftware, className: LinkClasses.Default }, { children: "main website" }), void 0), ". The source code and the third-party libraries it depends on are governed by different licenses; please consult the license files specific to each software package before embedding or modifying the code."] }, void 0) }, void 0)] }, void 0)
    },
    {
        header: 'Communications',
        body: _jsxs(_Fragment, { children: [_jsx("p", { children: _jsx(Text, { children: "If you provide us with your email address as part of creating an account, we may contact you in regards to billing issues or to announce important service changes." }, void 0) }, void 0), _jsx("p", { children: _jsx(Text, { children: "If you provide us with your email address or in a bug report or other piece of feedback, we will follow up by email if requested." }, void 0) }, void 0), _jsx("p", { children: _jsxs(Text, { children: ["Otherwise, all news about our website, services and software will be communicated via the website or our email newsletter. You will not be automatically subscribed to the newsletter; if you want it, you can sign up\u00A0", _jsx(SameTabLink, Object.assign({ to: ExternalPageUrls.ZaberNewsletter, className: LinkClasses.Default }, { children: "here" }), void 0), "."] }, void 0) }, void 0)] }, void 0)
    },
    {
        header: 'Jurisdiction',
        body: _jsx("p", { children: _jsx(Text, { children: "You agree that legal disputes regarding our services, website and software will be resolved under the laws applicable in the province of British Columbia, Canada." }, void 0) }, void 0)
    },
    {
        header: 'User Account',
        body: _jsxs(_Fragment, { children: [_jsx("p", { children: _jsx(Text, { children: "If you create an account on this website, you are solely responsible for maintaining the confidentiality of your account details (username and password). You are responsible for all activities that occur under your account." }, void 0) }, void 0), _jsx("p", { children: _jsx(Text, { children: "We reserve the right to terminate accounts or modify or remove user-created online resources within our services at any time, for example, when a user is found to be abusing the services." }, void 0) }, void 0)] }, void 0)
    },
    {
        header: 'Service Level',
        body: _jsxs(_Fragment, { children: [_jsx("p", { children: _jsx(Text, { children: "We do not guarantee that the Virtual Device service will exactly match the feature sets, appearance and performance characteristics of real devices." }, void 0) }, void 0), _jsx("p", { children: _jsx(Text, { children: "We do not currently provide an uptime guarantee. During the initial beta period, the services may be taken down or restarted for maintenance purposes at any time and without warning." }, void 0) }, void 0), _jsx("p", { children: _jsx(Text, { children: "This software is currently in early access and subject to change without warning." }, void 0) }, void 0)] }, void 0)
    },
    {
        header: 'User Contributions',
        body: _jsx("p", { children: _jsxs(Text, { children: ["We encourage all feedback about the usefulness or our software and services, including bug reports and feature suggestions.  Please use the feedback forms\u00A0", _jsx(FeedbackLink, Object.assign({ className: LinkClasses.Default }, { children: "on this site" }), void 0), "\u00A0or in Zaber Launcher or Zaber Console, or mention your feedback to our Applications Engineers if you are already contacting them. We do not offer bug bounties or other compensation for suggestions."] }, void 0) }, void 0)
    }
];
