import { changeDictionary } from '@zaber/toolbox';
import { createReducer } from '../utils';
import { ActionTypes } from './actions';
const initialState = {
    mounted: false,
    nextId: 1,
    axes: {},
    errors: [],
    loadingCounter: 0,
};
const mount = state => (Object.assign(Object.assign({}, state), { mounted: true, errors: [], loadingCounter: 1 }));
const unmount = state => (Object.assign(Object.assign({}, state), { mounted: false }));
const addError = (state, { error }) => (Object.assign(Object.assign({}, state), { errors: [...state.errors, error] }));
const addAxis = (state, { data }) => (Object.assign(Object.assign({}, state), { axes: Object.assign(Object.assign({}, state.axes), { [String(state.nextId)]: Object.assign(Object.assign({ id: state.nextId }, data), { error: null, position: 0 }) }), nextId: state.nextId + 1 }));
const setError = (state, { id, error }) => (Object.assign(Object.assign({}, state), { axes: changeDictionary(state.axes, String(id), { error }) }));
const changeLoadingCounter = (state, { by }) => (Object.assign(Object.assign({}, state), { loadingCounter: state.loadingCounter + by }));
const updatePosition = (state, { position, id }) => (Object.assign(Object.assign({}, state), { axes: changeDictionary(state.axes, String(id), { position }) }));
export const reducer = createReducer({
    [ActionTypes.MOUNT]: mount,
    [ActionTypes.UNMOUNT]: unmount,
    [ActionTypes.ADD_ERROR]: addError,
    [ActionTypes.ADD_AXIS]: addAxis,
    [ActionTypes.SET_ERROR]: setError,
    [ActionTypes.CHANGE_LOADING_COUNTER]: changeLoadingCounter,
    [ActionTypes.UPDATE_POSITION]: updatePosition,
}, initialState);
