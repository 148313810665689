import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Text, TextArea, Button, Input } from '@zaber/react-library';
import { useSelector } from 'react-redux';
import { useActions } from '@zaber/toolbox/lib/redux';
import { Redirect } from 'react-router-dom';
import { browserHistory } from '../store/history';
import { PageUrls } from '../urls';
import { actions as userActionDefinitions } from '../user/actions';
import { selectLoggedInUserEmail, selectUserAccountDelete, IfAuth, AuthState } from '../user';
import { actions as feedbackActionDefinitions } from '../feedback/actions';
import { selectFeedbackText, selectFeedbackState } from '../feedback/selectors';
import { IconMessage } from '../components/IconMessage';
export const DeleteAccountConfirmation = () => {
    const userActions = useActions(userActionDefinitions);
    const feedbackActions = useActions(feedbackActionDefinitions);
    const userEmail = useSelector(selectLoggedInUserEmail);
    const { accountDeleteInProgress, accountDeleteError } = useSelector(selectUserAccountDelete);
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const text = useSelector(selectFeedbackText);
    const { feedbackSubmissionInProgress } = useSelector(selectFeedbackState);
    useEffect(() => {
        setPassword('');
        setPasswordError(accountDeleteError ? 'password-error' : '');
    }, [accountDeleteError]);
    return (_jsxs(_Fragment, { children: [(!confirm || accountDeleteError) && _jsx(IfAuth, Object.assign({ desiredState: AuthState.NotAuthenticated }, { children: _jsx(Redirect, { to: PageUrls.Login }, void 0) }), void 0), _jsxs("div", Object.assign({ className: "delete-account-confirm" }, { children: [_jsx(Text, Object.assign({ className: "farewell", t: Text.Type.H3 }, { children: "We are sorry to see you go!" }), void 0), !(!accountDeleteInProgress && !accountDeleteError && confirm) && _jsx("div", Object.assign({ className: "keep-account" }, { children: _jsx(Text, Object.assign({ e: Text.Emphasis.Light, onClick: () => browserHistory.goBack() }, { children: "Keep My Account" }), void 0) }), void 0), _jsxs("div", Object.assign({ className: "container" }, { children: [_jsx(Text, Object.assign({ t: Text.Type.H4, className: "feedback" }, { children: "Please let us know the reason why you are leaving and how we can improve our service:" }), void 0), _jsx(TextArea, { value: text, className: "textarea", "data-testid": "feedback-input", onValueChange: feedbackActions.setText }, void 0), _jsx(Button, Object.assign({ onClick: feedbackActions.sendFeedback, className: "feedback-button", color: "red", disabled: text === '' || feedbackSubmissionInProgress }, { children: "Submit Your Feedback" }), void 0), _jsx("br", {}, void 0), ((!accountDeleteInProgress && !confirm) || accountDeleteError) && _jsx(Text, Object.assign({ t: Text.Type.Body }, { children: "To confirm your decision, please re-enter your password:" }), void 0), _jsxs("div", Object.assign({ className: "password" }, { children: [((!accountDeleteInProgress && !confirm) || accountDeleteError) && _jsxs(_Fragment, { children: [_jsx(Input, { "data-testid": "password-input", className: passwordError, type: "password", value: password, onChange: e => setPassword(e.target.value) }, void 0), _jsx(Button, Object.assign({ color: "red", disabled: !password || accountDeleteInProgress, onClick: () => {
                                                    userActions.deleteUserAccount(userEmail, password);
                                                    setConfirm(true);
                                                } }, { children: "Confirm" }), void 0)] }, void 0), (!accountDeleteInProgress && !accountDeleteError && confirm) && _jsxs(_Fragment, { children: [_jsx(IconMessage, Object.assign({ icon: "Confirmation", color: "green", className: "status" }, { children: "Confirmed" }), void 0), _jsx(IfAuth, Object.assign({ desiredState: AuthState.NotAuthenticated }, { children: _jsx(Redirect, { to: PageUrls.VirtualDeviceHome }, void 0) }), void 0)] }, void 0), accountDeleteInProgress && _jsx(IconMessage, Object.assign({ icon: "Gear", className: "status", rotatingIcon: true }, { children: "Updating..." }), void 0)] }), void 0), accountDeleteError && _jsx(IconMessage, Object.assign({ icon: "ErrorWarning", color: "red" }, { children: "Password is incorrect" }), void 0)] }), void 0)] }), void 0)] }, void 0));
};
