import { EventEmitter } from 'events';
import * as Sentry from '@sentry/react';
import { environment } from '../environment';
/**
 * Emits an event when sentry captures an error.
 * Serves to allow application react to the event.
 */
export const errorEmitter = new EventEmitter();
const sendSentryEvents = environment.build !== 'dev';
function beforeSend(event) {
    if (typeof event.level === 'string' && event.level.match(/^(error|fatal|critical)$/)) {
        process.nextTick(() => {
            errorEmitter.emit('error');
            console.error(event); // eslint-disable-line no-console
        });
    }
    if (!sendSentryEvents) {
        return null;
    }
    return event;
}
const SENTRY_FAKE_DSN = 'https://12345@12345.ingest.sentry.io/12345';
export function initErrors() {
    const dsn = environment.sentryDsn;
    if (environment.build !== 'dev' && !dsn) {
        throw new Error(`Sentry.io token missing: ${dsn}`);
    }
    /* We keep sentry running in dev to benefit from the error capturing machinery
    but we prevent it from sending events to the server.
    We need a fake DSN to deceive sentry SDK. */
    Sentry.init({
        dsn: dsn || SENTRY_FAKE_DSN,
        release: environment.releaseName,
        beforeSend,
    });
}
export function handleUnexpectedError(err) {
    Sentry.captureException(err);
}
