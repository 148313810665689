import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router';
import { NOT_FOUND_CLASSES } from '../not_found/NotFound';
const CANONICAL_WEB = 'https://software.zaber.com';
const NOT_FOUND_SELECTOR = NOT_FOUND_CLASSES.map(c => `.${c}`).join('');
export const CanonicalLink = () => {
    const location = useLocation();
    useLayoutEffect(() => {
        let link = document.head.querySelector('link[rel="canonical"]');
        const created = link == null;
        if (link == null) {
            link = document.createElement('link');
            link.rel = 'canonical';
        }
        let canonical = location.pathname;
        if (document.body.querySelector(NOT_FOUND_SELECTOR)) {
            canonical = '/404';
        }
        link.href = `${CANONICAL_WEB}${canonical}`;
        if (created) {
            document.head.appendChild(link);
        }
    }, [location.pathname]);
    return null;
};
