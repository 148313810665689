import { actionBuilder } from '../utils';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["MOUNT"] = "DEMO_MOUNT";
    ActionTypes["UNMOUNT"] = "DEMO_UNMOUNT";
    ActionTypes["ADD_ERROR"] = "DEMO_ADD_ERROR";
    ActionTypes["ADD_AXIS"] = "DEMO_ADD_AXIS";
    ActionTypes["MOVE"] = "DEMO_MOVE";
    ActionTypes["SET_ERROR"] = "DEMO_SET_ERROR";
    ActionTypes["CHANGE_LOADING_COUNTER"] = "DEMO_CHANGE_LOADING_COUNTER";
    ActionTypes["UPDATE_POSITION"] = "DEMO_UPDATE_POSITION";
    ActionTypes["REFRESH_POSITION"] = "DEMO_REFRESH_POSITION";
})(ActionTypes || (ActionTypes = {}));
const buildAction = (type, value) => actionBuilder(type, value);
export const actions = {
    mount: () => buildAction(ActionTypes.MOUNT),
    unmount: () => buildAction(ActionTypes.UNMOUNT),
    addError: (error) => buildAction(ActionTypes.ADD_ERROR, { error }),
    addAxis: (data) => buildAction(ActionTypes.ADD_AXIS, { data }),
    move: (id, moveType) => buildAction(ActionTypes.MOVE, { id, moveType }),
    setError: (id, error) => buildAction(ActionTypes.SET_ERROR, { id, error }),
    changeLoadingCounter: (by) => buildAction(ActionTypes.CHANGE_LOADING_COUNTER, { by }),
    updatePosition: (id, position) => buildAction(ActionTypes.UPDATE_POSITION, { id, position }),
    refreshPosition: (id) => buildAction(ActionTypes.REFRESH_POSITION, { id }),
};
