var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from 'react';
import { Input } from '@zaber/react-library';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { selectTerminalHistory } from './selectors';
import { HistoryItemType } from './types';
const CURRENT_COMMAND_INDEX = 0;
export const TerminalInput = (_a) => {
    var _b;
    var { simulationId, onChange, onSendCommand } = _a, rest = __rest(_a, ["simulationId", "onChange", "onSendCommand"]);
    const [userInputCommand, setUserInputCommand] = useState('');
    const [historySelectionIndex, setHistorySelectionIndex] = useState(CURRENT_COMMAND_INDEX);
    const inputRef = useRef(null);
    const history = useSelector(selectTerminalHistory);
    const historyTerminalRequests = (_b = history[simulationId]) === null || _b === void 0 ? void 0 : _b.filter(historyItem => historyItem.type === HistoryItemType.LocalUserRequest);
    const currentCommand = useMemo(() => {
        if (!historyTerminalRequests || historySelectionIndex === CURRENT_COMMAND_INDEX) {
            return userInputCommand;
        }
        else {
            // All the indexes > 0 select command strings from the history in LIFO order
            return _.nth(historyTerminalRequests, -historySelectionIndex).text;
        }
    }, [historySelectionIndex, userInputCommand]);
    useEffect(() => onChange === null || onChange === void 0 ? void 0 : onChange(currentCommand), [currentCommand]);
    useEffect(() => {
        const input = inputRef.current;
        if (input && input === document.activeElement) {
            input.setSelectionRange(input.value.length, input.value.length);
        }
    }, [historySelectionIndex]);
    return (_jsx(Input, Object.assign({ autoComplete: "off", ref: inputRef, placeholder: "Type commands here... Ex. /home", value: currentCommand, onKeyDown: e => {
            var _a, _b;
            switch (e.key) {
                case 'Enter':
                    onSendCommand === null || onSendCommand === void 0 ? void 0 : onSendCommand(currentCommand);
                    setUserInputCommand('');
                    setHistorySelectionIndex(CURRENT_COMMAND_INDEX);
                    break;
                case 'ArrowUp':
                    e.preventDefault();
                    setHistorySelectionIndex(_.clamp(historySelectionIndex + 1, 0, (_a = historyTerminalRequests === null || historyTerminalRequests === void 0 ? void 0 : historyTerminalRequests.length) !== null && _a !== void 0 ? _a : 0));
                    break;
                case 'ArrowDown':
                    e.preventDefault();
                    setHistorySelectionIndex(_.clamp(historySelectionIndex - 1, 0, (_b = historyTerminalRequests === null || historyTerminalRequests === void 0 ? void 0 : historyTerminalRequests.length) !== null && _b !== void 0 ? _b : 0));
                    break;
            }
        }, onValueChange: value => {
            setUserInputCommand(value);
            setHistorySelectionIndex(CURRENT_COMMAND_INDEX);
        }, spellCheck: false, title: "Terminal input" }, rest), void 0));
};
