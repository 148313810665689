import { createSelector } from 'reselect';
import { selectVirtualDevice } from '../store';
import { SimulationType } from '../virtual_device_api';
export const selectAllSimulations = createSelector(selectVirtualDevice, state => state.simulations);
export const selectPrivateSimulations = createSelector(selectVirtualDevice, state => { var _a, _b, _c; return (_c = (_b = (_a = state.simulations) === null || _a === void 0 ? void 0 : _a.filter) === null || _b === void 0 ? void 0 : _b.call(_a, sim => sim.type === SimulationType.Private)) !== null && _c !== void 0 ? _c : null; });
export const selectPrivateSimulationsLoading = createSelector(selectVirtualDevice, state => state.privateSimulationsLoading);
export const selectCollapsedPrivateSimulations = createSelector(selectVirtualDevice, state => state.collapsedPrivateSimulations);
export const selectSimulationProducts = createSelector(selectVirtualDevice, state => state.simulationProducts);
export const selectSimulationProductsLoading = createSelector(selectVirtualDevice, state => state.simulationProductsLoading);
export const selectPublicSimulation = createSelector(selectVirtualDevice, state => { var _a, _b, _c; return (_c = (_b = (_a = state.simulations) === null || _a === void 0 ? void 0 : _a.find) === null || _b === void 0 ? void 0 : _b.call(_a, sim => sim.type === SimulationType.Public)) !== null && _c !== void 0 ? _c : null; });
export const selectCreatingSimulations = createSelector(selectVirtualDevice, state => state.creatingSimulations);
export const selectSimulationCreationError = createSelector(selectVirtualDevice, state => state.simulationCreationError);
export const selectCreationRetryInfo = createSelector(selectVirtualDevice, state => state.retryingCreation);
export const selectTurnOnRetryInfo = createSelector(selectVirtualDevice, state => state.retryingTurnOn);
export const selectSimulationRenameError = createSelector(selectVirtualDevice, state => state.renameError);
export const selectAllDevices = createSelector(selectVirtualDevice, state => state.devices);
export const selectAllAxes = createSelector(selectVirtualDevice, state => state.axes);
export const selectSimulationsGettingRemoved = createSelector(selectVirtualDevice, state => state.simulationsGettingRemoved);
export const selectChainBuilder = createSelector(selectVirtualDevice, state => state.chainBuilder);
export const selectIsBuildingChain = createSelector(selectChainBuilder, builder => builder.devices != null);
export const selectIsFinalizingChain = createSelector(selectChainBuilder, builder => builder.isFinalizing);
export const selectIsPollingLocked = createSelector(selectVirtualDevice, state => state.pollLock > 0);
