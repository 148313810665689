import { ActionTypes as UserActionTypes } from '../user/actions';
import { ActionTypes as VirtualDeviceActionTypes } from '../virtual_device/actions';
import { createReducer } from '../utils';
import { ActionTypes } from './actions';
import { ViewModes } from './types';
const initialState = {
    currentSimulationId: null,
    currentViewMode: ViewModes.NoSimulationSelected,
    currentSimulationExtraData: {},
    simulationCheckError: null,
    simulationSetupInProgress: false,
    simulationSetupError: null,
    axisWarningFlags: {},
    selectedDeviceIndex: null,
};
const checkSimulation = (state) => (Object.assign(Object.assign({}, state), { currentViewMode: ViewModes.CheckingSimulation, simulationSetupInProgress: false, simulationSetupError: null }));
const simulationCheckDone = (state, { simulationId, error }) => (Object.assign(Object.assign({}, state), { currentViewMode: error ? ViewModes.CheckError : ViewModes.Simulation, currentSimulationId: simulationId !== null && simulationId !== void 0 ? simulationId : null, simulationCheckError: error !== null && error !== void 0 ? error : null, simulationSetupInProgress: false, simulationSetupError: null }));
const setupSimulation = (state) => (Object.assign(Object.assign({}, state), { simulationSetupInProgress: true, simulationSetupError: null }));
const simulationSetupDone = (state, { simulationId, error }) => state.currentSimulationId !== simulationId ? state : Object.assign(Object.assign({}, state), { simulationSetupInProgress: false, simulationSetupError: error !== null && error !== void 0 ? error : null });
const websiteProductDataLoaded = (state, { deviceId, productData }) => (Object.assign(Object.assign({}, state), { currentSimulationExtraData: Object.assign(Object.assign({}, state.currentSimulationExtraData), { [deviceId]: productData }) }));
const moveAxis = (state, { axisKey }) => (Object.assign(Object.assign({}, state), { axisWarningFlags: Object.assign(Object.assign({}, state.axisWarningFlags), { [axisKey]: null }) }));
const storeAxisWarningFlags = (state, { axisKey, warningFlags }) => (Object.assign(Object.assign({}, state), { axisWarningFlags: Object.assign(Object.assign({}, state.axisWarningFlags), { [axisKey]: warningFlags }) }));
const selectOrDeselectDevice = (state, { index }) => (Object.assign(Object.assign({}, state), { selectedDeviceIndex: index == null || index === state.selectedDeviceIndex ? null : index }));
const createSimulation = (state) => (Object.assign(Object.assign({}, state), { currentViewMode: ViewModes.CreatingSimulation }));
const simulationCreated = (state, { error }) => (Object.assign(Object.assign({}, state), { currentViewMode: error ? ViewModes.CreationError : state.currentViewMode }));
const simulationRemoved = (state, { simulationId }) => (Object.assign(Object.assign({}, state), { currentViewMode: simulationId === state.currentSimulationId ? ViewModes.SimulationRemoved : state.currentViewMode }));
const simulationError = (state, { simulationId }) => (Object.assign(Object.assign({}, state), { currentViewMode: simulationId === state.currentSimulationId ? ViewModes.SimulationError : state.currentViewMode }));
const clearSimulationError = (state) => (Object.assign(Object.assign({}, state), { currentViewMode: state.currentViewMode !== ViewModes.SimulationError ? state.currentViewMode :
        state.currentSimulationId ? ViewModes.Simulation : ViewModes.NoSimulationSelected }));
const resetState = () => initialState;
export const reducer = createReducer({
    [ActionTypes.CHECK_SIMULATION]: checkSimulation,
    [ActionTypes.SIMULATION_CHECK_DONE]: simulationCheckDone,
    [ActionTypes.SETUP_SIMULATION]: setupSimulation,
    [ActionTypes.SIMULATION_SETUP_DONE]: simulationSetupDone,
    [ActionTypes.WEBSITE_PRODUCT_DATA_LOADED]: websiteProductDataLoaded,
    [ActionTypes.RESET_STATE]: resetState,
    [ActionTypes.MOVE_SIMULATION_AXIS]: moveAxis,
    [ActionTypes.STORE_AXIS_WARNING_FLAGS]: storeAxisWarningFlags,
    [ActionTypes.DEVICE_CLICKED]: selectOrDeselectDevice,
    [UserActionTypes.LOGIN]: resetState,
    [UserActionTypes.LOGOUT]: resetState,
    [VirtualDeviceActionTypes.CREATE_SIMULATION]: createSimulation,
    [VirtualDeviceActionTypes.SIMULATION_CREATED]: simulationCreated,
    [VirtualDeviceActionTypes.SIMULATION_REMOVED]: simulationRemoved,
    [VirtualDeviceActionTypes.UPDATE_SIMULATION_ERROR]: simulationError,
    [VirtualDeviceActionTypes.CLEAR_SIMULATION_ERROR]: clearSimulationError,
}, initialState);
