import { Amplify } from '@aws-amplify/core';
import { environment } from './environment';
export function initAmplify() {
    Amplify.configure({
        Auth: {
            identityPoolId: environment.cognitoIdentityPoolId,
            region: environment.awsRegion,
            userPoolId: environment.cognitoUserPoolId,
            userPoolWebClientId: environment.cognitoUserPoolClientId,
            mandatorySignIn: false,
        }
    });
}
