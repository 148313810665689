import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from '@zaber/react-library';
import { useEffect } from 'react';
import { Footer } from '../components/Footer';
import { NavigationBar } from '../navigation_bar';
import { useActions } from '../utils';
import { actions as userActionDefinitions } from '../user/actions';
import { PageTitle } from '../components/PageTitle';
import { DeleteAccountConfirmation } from './DeleteAccountConfirmation';
export const DeleteAccount = () => {
    const actions = useActions(userActionDefinitions);
    useEffect(() => () => { actions.resetErrors(); }, []);
    return (_jsxs("div", Object.assign({ className: "delete-acccount page" }, { children: [_jsx(PageTitle, { children: "Delete Account" }, void 0), _jsx(NavigationBar, {}, void 0), _jsxs("div", Object.assign({ className: "content" }, { children: [_jsx(Text, Object.assign({ t: Text.Type.H3, e: Text.Emphasis.Light, className: "page-title" }, { children: "Delete My Account" }), void 0), _jsx(DeleteAccountConfirmation, {}, void 0)] }), void 0), _jsx(Footer, {}, void 0)] }), void 0));
};
