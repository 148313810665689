/**
 * A mapping of old URLs to replacement pages.
 * Note that pages starting with `dev-portal` must be handled by the developer-portal redirects rather than here.
 * Further, if you redirect to a page served by AWS S3, you must add a trailing slash to the URL,
 * otherwise AWS will trigger an extra redirect which will cause the Google web-crawler to complain.
 * See details here: https://docs.aws.amazon.com/AmazonS3/latest/userguide/IndexDocumentSupport.html#IndexDocumentsandFolders
 */
export const REDIRECTS = {
    '/downloads/docs/motion-library/:version/ascii/howtos/device_db/': '/motion-library/docs/guides/device_db/',
    '/downloads/docs/motion-library/:version/ascii/howtos/device_io/': '/motion-library/docs/guides/device_io/',
    '/downloads/docs/motion-library/:version/ascii/howtos/gcode/': '/motion-library/docs/guides/gcode/',
    '/downloads/docs/motion-library/:version/ascii/howtos/parallel_movements/': '/motion-library/docs/guides/parallel_movements/',
    '/downloads/docs/motion-library/:version/ascii/howtos/settings_unit_conversion/': '/motion-library/docs/guides/settings_unit_conversion/',
    '/downloads/docs/motion-library/:version/ascii/howtos/settings/': '/motion-library/docs/guides/settings/',
    '/downloads/docs/motion-library/:version/ascii/howtos/tcp/': '/motion-library/docs/guides/tcp/',
    '/downloads/docs/motion-library/:version/ascii/howtos/warnings/': '/motion-library/docs/guides/warnings/',
    '/downloads/docs/motion-library/:version/ascii/references/cpp/': '/motion-library/docs/support/older_versions/',
    '/downloads/docs/motion-library/:version/ascii/references/java/': '/motion-library/docs/support/older_versions/',
    '/downloads/docs/motion-library/:version/ascii/references/python_binary/': '/motion-library/docs/support/older_versions/',
    '/downloads/docs/motion-library/:version/ascii/support/changelog/': '/motion-library/docs/support/changelog/',
    '/downloads/docs/motion-library/:version/ascii/tutorials/install/': '/motion-library/docs/tutorials/',
    '/downloads/docs/motion-library/:version/ascii/tutorials/install/cpp/': '/motion-library/docs/tutorials/install/cpp/',
    '/downloads/docs/motion-library/:version/ascii/tutorials/introduction/': '/motion-library/docs/tutorials/',
    '/motion-library/api/py/ascii/process': '/motion-library/api/py/product/process/',
    '/motion-library/api/py/ascii/processcontrollersourcesensor': '/motion-library/api/py/product/processcontrollersourcesensor/',
    '/motion-library/docs/binary/introductionRelease': '/motion-library/docs/binary/introduction/',
    '/motion-library/docs/howtos/arbitrary_commands_binary': '/motion-library/docs/binary/guides/arbitrary_commands_binary/',
    '/motion-library/docs/references/python_binary': '/motion-library/docs/binary/introduction/',
    '/motion-library/docs/tutorials/initialize_binary': '/motion-library/docs/binary/tutorials/initialize_binary/',
    '/motion-library/docs/tutorials/introduction': '/motion-library/docs/tutorials/',
    '/motion-library/docs/tutorials/move_device_binary': '/motion-library/docs/binary/tutorials/move_device_binary/',
    '/motion-library/docs/tutorials/move_device': '/motion-library/docs/tutorials/code/',
    '/motion-library/docs/tutorials/open_port': '/motion-library/docs/tutorials/code/',
    '/virtual-device/docs': '/virtual-device/home',
};
