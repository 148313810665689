import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EntityKeyType, getKeyData, getSubKey } from './keys';
import { selectAllAxes, selectAllDevices, selectAllSimulations } from './selectors';
export function useSimulationById(simulationId) {
    var _a;
    const allSimulations = useSelector(selectAllSimulations);
    return (_a = allSimulations === null || allSimulations === void 0 ? void 0 : allSimulations.find(sim => sim.id === simulationId)) !== null && _a !== void 0 ? _a : null;
}
export function useSimulation(key) {
    const allSimulations = useSelector(selectAllSimulations);
    return useMemo(() => {
        var _a;
        if (key == null) {
            return null;
        }
        const keyData = getKeyData(key);
        const simulationId = keyData[EntityKeyType.SIMULATION];
        return (_a = allSimulations === null || allSimulations === void 0 ? void 0 : allSimulations.find(sim => sim.id === simulationId)) !== null && _a !== void 0 ? _a : null;
    }, [allSimulations, key]);
}
export function useDevice(key) {
    const allDevices = useSelector(selectAllDevices);
    return useMemo(() => {
        var _a;
        if (key == null) {
            return null;
        }
        try {
            const deviceKey = getSubKey(key, EntityKeyType.DEVICE);
            return (_a = allDevices === null || allDevices === void 0 ? void 0 : allDevices[deviceKey]) !== null && _a !== void 0 ? _a : null;
        }
        catch (e) {
            return null;
        }
    }, [allDevices, key]);
}
export function useAxis(key) {
    const allAxes = useSelector(selectAllAxes);
    return useMemo(() => {
        var _a;
        if (key == null) {
            return null;
        }
        try {
            const axisKey = getSubKey(key, EntityKeyType.AXIS);
            return (_a = allAxes === null || allAxes === void 0 ? void 0 : allAxes[axisKey]) !== null && _a !== void 0 ? _a : null;
        }
        catch (e) {
            return null;
        }
    }, [allAxes, key]);
}
