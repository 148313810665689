var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { MotionType } from '@zaber/virtual-device-display';
import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Colors } from '@zaber/react-library';
import { environment } from '../environment';
import { EntityKeyType, getSubKey } from '../virtual_device';
import { selectAllAxes } from '../virtual_device/selectors';
import { normalizeRGB, stringToRGB, useActions } from '../utils';
import { actions as actionDefinitions } from './actions';
import { selectCurrentSimulation, selectSelectedDevice } from './selectors';
const DefaultScene = React.lazy(() => __awaiter(void 0, void 0, void 0, function* () {
    return ({
        default: (yield import('@zaber/virtual-device-display/dist/lib/elements/Scenes/DefaultScene')).DefaultScene
    });
}));
const ZaberModel = React.lazy(() => __awaiter(void 0, void 0, void 0, function* () {
    return ({
        default: (yield import('@zaber/virtual-device-display/dist/lib/elements/Models/ZaberModel')).ZaberModel
    });
}));
const HIGHLIGHT_COLOR = normalizeRGB(stringToRGB(Colors.orange));
const stopWheelEvent = (e) => e === null || e === void 0 ? void 0 : e.preventDefault();
export const Viewer3DScene = ({ cameraResetToken }) => {
    var _a, _b;
    const actions = useActions(actionDefinitions);
    const divRef = useRef(null);
    const currentSimulation = useSelector(selectCurrentSimulation);
    const allAxes = (_a = useSelector(selectAllAxes)) !== null && _a !== void 0 ? _a : {};
    const selectedDevice = useSelector(selectSelectedDevice);
    const axisStates = useMemo(() => {
        const axisKeys = [];
        let axisIndex = 0;
        currentSimulation === null || currentSimulation === void 0 ? void 0 : currentSimulation.components.forEach((component, i) => {
            var _a;
            if (component.modelManifest) {
                const keys = (_a = component.modelManifest.axes) === null || _a === void 0 ? void 0 : _a.map(axis => {
                    var _a, _b;
                    if (axis.motionType !== MotionType.None) {
                        return (_b = (_a = currentSimulation === null || currentSimulation === void 0 ? void 0 : currentSimulation.axes) === null || _a === void 0 ? void 0 : _a[axisIndex++]) !== null && _b !== void 0 ? _b : '';
                    }
                    else {
                        return '';
                    }
                });
                axisKeys.push(keys !== null && keys !== void 0 ? keys : []);
            }
            else {
                axisKeys.push([]);
            }
        });
        const axisStates = axisKeys.map(keys => keys.map(key => {
            if (key != null && key.length > 0) {
                const k = getSubKey(key, EntityKeyType.AXIS);
                if (k in allAxes) {
                    return allAxes[k];
                }
            }
            return null;
        }));
        return axisStates;
    }, [allAxes, currentSimulation === null || currentSimulation === void 0 ? void 0 : currentSimulation.components]);
    // Prevent browser UI zoom when focus is on the 3D canvas.
    // This doesn't prevent Babylon from using the event to zoom the camera.
    // You can still get the browser zoom by mousing over one of the overlays or the nav bar.
    useLayoutEffect(() => {
        const div = divRef.current;
        div.addEventListener('wheel', stopWheelEvent);
        return () => div.removeEventListener('wheel', stopWheelEvent);
    }, []);
    return (_jsx("div", Object.assign({ className: "viewer", ref: divRef }, { children: ((_b = currentSimulation === null || currentSimulation === void 0 ? void 0 : currentSimulation.components) === null || _b === void 0 ? void 0 : _b.length) && _jsx(React.Suspense, Object.assign({ fallback: null }, { children: _jsx(DefaultScene, Object.assign({ cameraResetToken: cameraResetToken, inertia: 0.666, panSensitivity: 5, zoomSensitivity: 0.05, modelHighlights: selectedDevice != null ? { [selectedDevice.toString()]: HIGHLIGHT_COLOR } : {} }, { children: _jsx(React.Suspense, Object.assign({ fallback: null }, { children: currentSimulation.components.map((component, i) => {
                        if (!component.modelManifest) {
                            return null;
                        }
                        return _jsx(ZaberModel, { modelId: i.toString(), onClicked: () => actions.deviceClicked(i), baseUrl: environment.modelBaseUrl, pos: axisStates[i].map(axis => { var _a; return axis ? axis.location * ((_a = axis.locationNativeToDefaultUnitScale) !== null && _a !== void 0 ? _a : 1.0) : 0; }), translation: { x: 200 * i - 100 * (axisStates.length - 1), y: 0, z: 0 }, manifestEntry: component.modelManifest }, component.productName);
                    }) }), void 0) }), void 0) }), void 0) }), void 0));
};
