import { createReducer } from '../utils';
import { ActionTypes } from './actions';
const initialState = {
    feedbackText: '',
    feedbackSubmissionInProgress: false,
    feedbackSubmitted: false,
    feedbackError: null,
    feedbackAnonymous: false,
    feedbackEmail: '',
    feedbackModalState: 'closed',
};
const setText = (state, { text }) => (Object.assign(Object.assign({}, state), { feedbackText: text }));
const sendFeedback = (state) => (Object.assign(Object.assign({}, state), { feedbackSubmissionInProgress: true, feedbackModalState: 'sending', feedbackError: null }));
const sendFeedbackDone = (state, { error }) => (Object.assign(Object.assign({}, state), { feedbackText: error ? state.feedbackText : '', feedbackSubmissionInProgress: false, feedbackSubmitted: !error, feedbackError: error !== null && error !== void 0 ? error : null, feedbackModalState: error ? 'error' : 'sent' }));
const setFeedbackModalState = (state, { feedbackModalState }) => (Object.assign(Object.assign(Object.assign(Object.assign({}, state), { feedbackModalState }), (feedbackModalState === 'closed' && state.feedbackModalState === 'sent'
    ? {
        feedbackText: '',
        feedbackSubmissionInProgress: false,
        feedbackSubmitted: false,
        feedbackError: null,
    }
    : {})), (feedbackModalState === 'closed' && state.feedbackModalState === 'error'
    ? {
        feedbackSubmissionInProgress: false,
        feedbackSubmitted: false,
        feedbackError: null,
    }
    : {})));
const setFeedbackAnonymous = (state, { anonymous }) => (Object.assign(Object.assign({}, state), { feedbackAnonymous: anonymous }));
const setFeedbackEmail = (state, { email }) => (Object.assign(Object.assign({}, state), { feedbackEmail: email }));
export const reducer = createReducer({
    [ActionTypes.SET_FEEDBACK_MODAL_STATE]: setFeedbackModalState,
    [ActionTypes.SEND_FEEDBACK]: sendFeedback,
    [ActionTypes.SEND_FEEDBACK_DONE]: sendFeedbackDone,
    [ActionTypes.SET_FEEDBACK_TEXT]: setText,
    [ActionTypes.SET_FEEDBACK_ANONYMOUS]: setFeedbackAnonymous,
    [ActionTypes.SET_FEEDBACK_EMAIL]: setFeedbackEmail,
}, initialState);
