import { actionBuilder } from '@zaber/toolbox/lib/redux';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["LOAD_ACCESS_TOKENS"] = "USER_LOAD_ACCESS_TOKENS";
    ActionTypes["ACCESS_TOKENS_LOADED"] = "USER_ACCESS_TOKENS_LOADED";
    ActionTypes["ACCESS_TOKEN_CREATE_WANTED"] = "ACCESS_TOKEN_CREATE_WANTED";
    ActionTypes["CREATE_ACCESS_TOKEN"] = "USER_CREATE_ACCEDD_TOKEN";
    ActionTypes["ACCESS_TOKEN_CREATED"] = "USER_ACCESS_TOKEN_CREATED";
    ActionTypes["CLEAR_CREATED_ACCESS_TOKEN_DATA"] = "USER_CLEAR_CREATED_ACCESS_TOKEN_DATA";
    ActionTypes["DELETE_ACCESS_TOKEN"] = "USER_DELETE_ACCESS_TOKEN";
    ActionTypes["ACCESS_TOKEN_DELETED"] = "USER_ACCESS_TOKEN_DELETED";
    ActionTypes["CHOOSE_TOKEN_FOR_DELETION"] = "USER_ACCESS_TOKEN_CHOOSE_TOKEN_FOR_DELETION";
})(ActionTypes || (ActionTypes = {}));
const buildAction = (type, value) => actionBuilder(type, value);
export const actions = {
    loadAccessTokens: () => buildAction(ActionTypes.LOAD_ACCESS_TOKENS),
    accessTokensLoaded: (tokens) => buildAction(ActionTypes.ACCESS_TOKENS_LOADED, { tokens }),
    accessTokensLoadError: (error) => buildAction(ActionTypes.ACCESS_TOKENS_LOADED, { error }),
    updateCreateAccessTokenWanted: (value) => buildAction(ActionTypes.ACCESS_TOKEN_CREATE_WANTED, { value }),
    createAccessToken: (description, expiryDate, scopes) => buildAction(ActionTypes.CREATE_ACCESS_TOKEN, { description, expiryDate, scopes }),
    accessTokenCreated: (tokenId, token) => buildAction(ActionTypes.ACCESS_TOKEN_CREATED, { tokenId, token }),
    accessTokenCreationError: (error) => buildAction(ActionTypes.ACCESS_TOKEN_CREATED, { error }),
    clearCreatedAccessTokenData: () => buildAction(ActionTypes.CLEAR_CREATED_ACCESS_TOKEN_DATA),
    deleteAccessToken: (tokenId) => buildAction(ActionTypes.DELETE_ACCESS_TOKEN, { tokenId }),
    accessTokenDeleted: (tokenId) => buildAction(ActionTypes.ACCESS_TOKEN_DELETED, { tokenId }),
    accessTokenDeletionError: (tokenId, error) => buildAction(ActionTypes.ACCESS_TOKEN_DELETED, { tokenId, error }),
    chooseTokenForDeletion: (tokenId) => buildAction(ActionTypes.CHOOSE_TOKEN_FOR_DELETION, { tokenId }),
};
