import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import 'setimmediate';
import 'reflect-metadata';
import { initAmplify } from './amplify';
import App from './App';
import { createContainer } from './container';
import { initErrors } from './errors';
import './styles/index.scss';
import { configureIot } from './iot';
import reportWebVitals from './reportWebVitals';
import { reduxStoreSymbol } from './store';
import { buildStore } from './store/build';
initAmplify();
initErrors();
configureIot();
const container = createContainer();
container.bind(reduxStoreSymbol).toDynamicValue(() => buildStore());
const store = container.get(reduxStoreSymbol);
ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(App, { store: store }, void 0) }, void 0), document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
