import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Icons, LinkClasses, Text } from '@zaber/react-library';
import { useActions } from '@zaber/toolbox/lib/redux';
import { WarningFlags } from '@zaber/motion/dist/lib/ascii';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useAxis, useDevice } from '../virtual_device/hooks';
import { getKeyData, EntityKeyType } from '../virtual_device/keys';
import { SimulationMoveTypes } from '../virtual_device_api';
import { NewTabLink } from '../components/Links';
import { ExternalPageUrls, getProtocolManualLink } from '../urls';
import { actions as actionDefinitions } from './actions';
import { selectAxisWarningFlags, selectCurrentSimulationExtraData } from './selectors';
import { AxisWarningFlagType } from './types';
const WarningFlagMessage = ({ axisKey, axisWarningFlag: { type, warningFlag, message } }) => {
    const axis = useAxis(axisKey);
    const device = useDevice(axisKey);
    let warningFlagText = null;
    if (warningFlag) {
        const docsUrl = getProtocolManualLink({
            device: device === null || device === void 0 ? void 0 : device.identity.name,
            peripheral: axis === null || axis === void 0 ? void 0 : axis.identity.peripheralName,
            firmware: device === null || device === void 0 ? void 0 : device.identity.firmwareVersion,
            warningFlag
        });
        warningFlagText = _jsxs(_Fragment, { children: ["Warning Flag:\u00A0", _jsx(Text, Object.assign({ f: Text.Family.Mono }, { children: warningFlag }), void 0), "\u00A0 (", _jsx(NewTabLink, Object.assign({ to: docsUrl }, { children: "more details" }), void 0), ")"] }, void 0);
    }
    switch (type) {
        case AxisWarningFlagType.Note:
            return (_jsxs("div", Object.assign({ className: "info warning-flag icon-message" }, { children: [_jsx(Icons.ErrorNote, {}, void 0), _jsxs(Text, { children: [warningFlagText, message && _jsxs("div", Object.assign({ className: "details" }, { children: ["Note: ", message] }), void 0)] }, void 0)] }), void 0));
        case AxisWarningFlagType.Warning:
            return (_jsxs("div", Object.assign({ className: "warning warning-flag icon-message" }, { children: [_jsx(Icons.ErrorWarning, {}, void 0), _jsxs(Text, { children: [warningFlagText, message && _jsxs("div", Object.assign({ className: "details" }, { children: ["Warning: ", message] }), void 0)] }, void 0)] }), void 0));
        case AxisWarningFlagType.Fault:
            return (_jsxs("div", Object.assign({ className: "error warning-flag icon-message" }, { children: [_jsx(Icons.ErrorFault, {}, void 0), _jsxs(Text, { children: [warningFlagText, message && _jsxs("div", Object.assign({ className: "details" }, { children: ["Fault: ", message] }), void 0)] }, void 0)] }), void 0));
        default:
            return (_jsxs("div", Object.assign({ className: "error icon-message" }, { children: [_jsx(Icons.ErrorFault, {}, void 0), _jsxs(Text, { children: ["Some unknown error has occured", message && _jsx("div", Object.assign({ className: "details" }, { children: message }), void 0)] }, void 0)] }), void 0));
    }
};
export const ViewerControlsAxis = ({ axisKey, selected, onSelected }) => {
    var _a, _b, _c;
    const actions = useActions(actionDefinitions);
    const axis = useAxis(axisKey);
    const device = useDevice(axisKey);
    const allWarningFlags = useSelector(selectAxisWarningFlags);
    const extra = useSelector(selectCurrentSimulationExtraData);
    const url = (_c = (_b = extra[(_a = device === null || device === void 0 ? void 0 : device.identity.deviceId) !== null && _a !== void 0 ? _a : 0]) === null || _b === void 0 ? void 0 : _b.url) !== null && _c !== void 0 ? _c : null;
    const warningFlags = allWarningFlags[axisKey];
    const worstError = warningFlags ? _.maxBy(warningFlags, flag => flag.type) : null;
    const needsHoming = !!(warningFlags === null || warningFlags === void 0 ? void 0 : warningFlags.find(flag => flag.warningFlag === WarningFlags.NO_REFERENCE_POSITION));
    const keyData = getKeyData(axisKey);
    const deviceAddress = Number(keyData[EntityKeyType.DEVICE]);
    const axisNumber = Number(keyData[EntityKeyType.AXIS]);
    if (!axis) {
        return _jsx("div", Object.assign({ className: "axis-container" }, { children: "No axis data available for given axis key." }), void 0);
    }
    return (_jsxs("div", Object.assign({ className: classNames('axis-container', { selected }), onClick: () => {
            if (!selected) {
                onSelected();
            }
        } }, { children: [_jsxs("div", Object.assign({ className: "axis-label" }, { children: [url && _jsxs(_Fragment, { children: [_jsxs(Text, Object.assign({ t: Text.Type.H5 }, { children: [deviceAddress, ":\u00A0"] }), void 0), _jsx(NewTabLink, Object.assign({ to: `${ExternalPageUrls.ZaberBaseUrl}${url}`, className: LinkClasses.Default }, { children: _jsx(Text, Object.assign({ t: Text.Type.H5 }, { children: device === null || device === void 0 ? void 0 : device.identity.name }), void 0) }), void 0)] }, void 0), !url && _jsxs(Text, Object.assign({ t: Text.Type.H5 }, { children: [deviceAddress, ":\u00A0", device === null || device === void 0 ? void 0 : device.identity.name] }), void 0), (axis === null || axis === void 0 ? void 0 : axis.identity.peripheralName) && _jsxs(_Fragment, { children: [_jsx(Icons.ArrowCollapsed, {}, void 0), _jsxs(Text, Object.assign({ t: Text.Type.H5 }, { children: [axisNumber, ":\u00A0", axis === null || axis === void 0 ? void 0 : axis.identity.peripheralName] }), void 0)] }, void 0)] }), void 0), _jsxs("div", Object.assign({ className: "movement-buttons-container" }, { children: [_jsx(Icons.Home, { appearsClickable: true, title: "Home", highlight: needsHoming ? 'on' : 'off', onClick: () => actions.moveAxis(axisKey, SimulationMoveTypes.Home) }, void 0), _jsx(Icons.LeftFast, { appearsClickable: true, title: "Move towards home", onClick: () => actions.moveAxis(axisKey, SimulationMoveTypes.ToMin) }, void 0), _jsx(Icons.Stop, { appearsClickable: true, title: "Stop", onClick: () => actions.moveAxis(axisKey, SimulationMoveTypes.Stop) }, void 0), _jsx(Icons.RightFast, { appearsClickable: true, title: "Move towards end", onClick: () => actions.moveAxis(axisKey, SimulationMoveTypes.ToMax) }, void 0)] }), void 0), worstError && _jsx(WarningFlagMessage, { axisKey: axisKey, axisWarningFlag: worstError }, void 0)] }), void 0));
};
