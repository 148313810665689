import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from '@zaber/react-library';
import { useSelector } from 'react-redux';
import { selectCreationRetryInfo, selectSimulationCreationError, selectSimulationRenameError } from '../virtual_device/selectors';
import { IconMessage } from '../components/IconMessage';
const Error = ({ title, children }) => (_jsxs(IconMessage, Object.assign({ icon: "ErrorFault", color: "red" }, { children: [_jsx(Text, Object.assign({ t: Text.Type.H5, className: "title" }, { children: title }), void 0), _jsx(Text, { children: children }, void 0)] }), void 0));
export const DeviceManagerErrors = () => {
    const simulationCreationErr = useSelector(selectSimulationCreationError);
    const renameErr = useSelector(selectSimulationRenameError);
    const createRetry = useSelector(selectCreationRetryInfo);
    const isRetrying = (createRetry && createRetry === 'chain');
    return (_jsxs("div", Object.assign({ className: "error-container" }, { children: [simulationCreationErr && !isRetrying && _jsx(Error, Object.assign({ title: "Unable to create Virtual Device" }, { children: simulationCreationErr }), void 0), renameErr && _jsx(Error, Object.assign({ title: "Unable to rename Virtual Device" }, { children: renameErr }), void 0)] }), void 0));
};
