import { createSelector } from 'reselect';
import { selectVirtualDeviceViewer, selectVirtualDevice } from '../store';
export const selectCurrentSimulation = createSelector(selectVirtualDevice, selectVirtualDeviceViewer, (virtualDeviceState, virtualDeviceViewerState) => {
    var _a, _b, _c;
    return (_c = (_b = (_a = virtualDeviceState.simulations) === null || _a === void 0 ? void 0 : _a.find) === null || _b === void 0 ? void 0 : _b.call(_a, sim => sim.id === virtualDeviceViewerState.currentSimulationId)) !== null && _c !== void 0 ? _c : null;
});
export const selectSimulationCheckError = createSelector(selectVirtualDeviceViewer, state => state.simulationCheckError);
export const selectSimulationSetupInProgress = createSelector(selectVirtualDeviceViewer, state => state.simulationSetupInProgress);
export const selectSimulationSetupError = createSelector(selectVirtualDeviceViewer, state => state.simulationSetupError);
export const selectCurrentSimulationExtraData = createSelector(selectVirtualDeviceViewer, state => state.currentSimulationExtraData);
export const selectAxisWarningFlags = createSelector(selectVirtualDeviceViewer, state => state.axisWarningFlags);
export const selectCurrentViewMode = createSelector(selectVirtualDeviceViewer, state => state.currentViewMode);
export const selectSelectedDevice = createSelector(selectVirtualDeviceViewer, state => state.selectedDeviceIndex);
