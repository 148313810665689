import _ from 'lodash';
export * from '@zaber/toolbox/lib/redux';
export * from '@zaber/toolbox';
/**
 * A more type safe version of `_.pick()`. But it is more limited because of it.
 * The `props` arguments will be typechecked to only allow for properties that actually exist
 * on the `object` and the returned object will have the correct types as well.
 * But this function's typechecking setup does not allow for the advanced functionality
 * provided by `_.pick()`
 * @param object The object to pick from
 * @param props The keys to pick out of the object. Lodash style paths are not supported.
 * @returns A new object with only the picked properties from the input object
 */
export const pick = (object, ...props) => _.pick(object, ...props);
