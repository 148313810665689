import { Config } from './app_components';
import { environment } from './environment';
export function configureIot() {
    Config.merge({
        aws: {
            region: environment.awsRegion,
            cognitoUserPoolId: environment.cognitoUserPoolId,
            cognitoIdentityPoolId: environment.cognitoIdentityPoolId,
            iotCoreHost: environment.awsIotHostUrl,
        },
        zaber: {
            apiUrl: environment.apiUrl,
            deviceDB: environment.build === 'production' ? 'public' : 'master',
        },
    });
}
