import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import classNames from 'classnames';
import { Text } from '@zaber/react-library';
import CopyToClipboard from 'react-copy-to-clipboard';
export const ID_COPY_MESSAGE_TIMEOUT = 2000;
export const VirtualDeviceId = ({ virtualDevice, disabled }) => {
    const [showThatIdWasCopied, setShowThatIdWasCopied] = useState(false);
    const text = _jsx(Text, Object.assign({ className: classNames('virtual-device-id', { disabled, copied: showThatIdWasCopied }) }, { children: showThatIdWasCopied ? 'Copied' : virtualDevice.id }), void 0);
    if (!showThatIdWasCopied && !disabled) {
        const onCopy = () => {
            setShowThatIdWasCopied(true);
            setTimeout(() => setShowThatIdWasCopied(false), ID_COPY_MESSAGE_TIMEOUT);
        };
        return (_jsx(CopyToClipboard, Object.assign({ text: virtualDevice.id, onCopy: onCopy }, { children: text }), void 0));
    }
    return text;
};
