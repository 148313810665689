export var EntityKeyType;
(function (EntityKeyType) {
    EntityKeyType["SIMULATION"] = "@simulation";
    EntityKeyType["DEVICE"] = "@device";
    EntityKeyType["AXIS"] = "@axis";
})(EntityKeyType || (EntityKeyType = {}));
const KEY_DELIMITER = '|';
const NEXT_KEY_TYPE = {
    [EntityKeyType.SIMULATION]: EntityKeyType.DEVICE,
    [EntityKeyType.DEVICE]: EntityKeyType.AXIS,
};
const KEY_TYPE_VALUES = Object.values(EntityKeyType);
const KEY_TYPES_ARRANGEMENT = Object.keys(NEXT_KEY_TYPE).reduce((arraySoFar, key, i, keysArray) => {
    arraySoFar.push(key);
    if (i === keysArray.length - 1) {
        arraySoFar.push(NEXT_KEY_TYPE[key]);
    }
    return arraySoFar;
}, []);
function checkValue(value) {
    if (typeof value === 'string') {
        if (value.length === 0) {
            throw new Error(`Cannot use given value in entity key: "${value}"`);
        }
    }
    else if (typeof value === 'number') {
        if (!Number.isInteger(value)) {
            throw new Error(`Cannot use given value in entity key: "${value}"`);
        }
    }
    else {
        throw new Error(`Cannot use given type of value in entity key: "${typeof value}"`);
    }
}
export function makeRootKey(simulationId) {
    checkValue(simulationId);
    return [EntityKeyType.SIMULATION, simulationId].join(KEY_DELIMITER);
}
export function extendKey(key, valueToAdd) {
    checkValue(valueToAdd);
    const keyType = getKeyType(key);
    const nextKeyType = NEXT_KEY_TYPE[keyType];
    if (!nextKeyType) {
        throw Error(`The given entity key can not be extended any further: ${key}`);
    }
    const oldKeyWithoutType = key.substr(key.indexOf(KEY_DELIMITER) + 1);
    return [nextKeyType, oldKeyWithoutType, valueToAdd].join(KEY_DELIMITER);
}
export function getKeyType(key) {
    if (!key) {
        throw Error(`Cannot get type from entity key: "${key}"`);
    }
    const type = key.substr(0, key.indexOf(KEY_DELIMITER));
    if (KEY_TYPE_VALUES.includes(type)) {
        return type;
    }
    else {
        throw Error(`Entity key type can not be recognized: ${key}`);
    }
}
export function getKeyData(key) {
    const [keyType, ...rest] = key.split(KEY_DELIMITER);
    const keyTypeIndex = KEY_TYPES_ARRANGEMENT.findIndex(type => type === keyType);
    if (keyTypeIndex < 0) {
        throw Error(`Key has invalid type: ${keyType}`);
    }
    if (keyTypeIndex !== rest.length - 1) {
        throw Error(`Key has invalid data for type "${keyType}": ${key}`);
    }
    return rest.reduce((dataSoFar, value, i) => {
        dataSoFar[KEY_TYPES_ARRANGEMENT[i]] = value;
        return dataSoFar;
    }, {});
}
export function getSubKey(key, subKeyType) {
    const keyType = getKeyType(key);
    if (keyType === subKeyType) {
        return key;
    }
    const keyTypeIndex = KEY_TYPES_ARRANGEMENT.findIndex(val => val === keyType);
    const subKeyTypeIndex = KEY_TYPES_ARRANGEMENT.findIndex(val => val === subKeyType);
    if (keyTypeIndex < subKeyTypeIndex) {
        throw Error(`Key "${key}" does not contain a sub key of type "${subKeyType}"`);
    }
    const keyData = getKeyData(key);
    return [
        subKeyType,
        ...KEY_TYPES_ARRANGEMENT
            .slice(0, subKeyTypeIndex + 1)
            .map(keyType => keyData[keyType])
    ].join(KEY_DELIMITER);
}
