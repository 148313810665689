function throwIfMissing(value) {
    if (typeof value !== 'string' || !value) {
        throw new Error('Required environment variable is not set or empty');
    }
    return value;
}
export const environment = (() => {
    var _a;
    const isTest = !!process.env.JEST_WORKER_ID;
    if (isTest) {
        return {
            build: 'dev',
            isTest,
            releaseName: 'Test',
            apiUrl: 'https://api.test.io',
            modelBaseUrl: './',
            awsRegion: 'region',
            cognitoUserPoolId: 'region_XXXXXX',
            cognitoUserPoolClientId: 'poolClientId',
            cognitoIdentityPoolId: 'identityPoolId',
            awsIotHostUrl: 'some_value.iot.some_region.amazonaws.com',
        };
    }
    const currentEnv = {
        build: process.env.REACT_APP_BUILD,
        isTest,
        releaseName: (_a = process.env.REACT_APP_RELEASE_NAME) !== null && _a !== void 0 ? _a : 'no-name-given',
        apiUrl: throwIfMissing(process.env.REACT_APP_API_URL),
        modelBaseUrl: throwIfMissing(process.env.MODEL_BASE_URL),
        awsRegion: throwIfMissing(process.env.REACT_APP_AWS_REGION),
        cognitoUserPoolId: throwIfMissing(process.env.REACT_APP_COGNITO_USER_POOL_ID),
        cognitoUserPoolClientId: throwIfMissing(process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID),
        cognitoIdentityPoolId: throwIfMissing(process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID),
        awsIotHostUrl: throwIfMissing(process.env.REACT_APP_AWS_IOT_HOST),
        sentryDsn: process.env.REACT_APP_SENTRY_DSN,
        disableAuthFeatures: !!process.env.REACT_APP_DISABLE_AUTHENTICATED_FEATURES,
    };
    return currentEnv;
})();
