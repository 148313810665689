import { createSelector } from 'reselect';
import { selectUserAccessToken } from '../store';
export const selectAccessTokens = createSelector(selectUserAccessToken, state => state.accessTokens);
export const selectAccessTokensLoading = createSelector(selectUserAccessToken, state => state.accessTokensLoading);
export const selectAccessTokensLoadError = createSelector(selectUserAccessToken, state => state.accessTokensLoadError);
export const selectAccessTokenCreateWanted = createSelector(selectUserAccessToken, state => state.accessTokenCreateWanted);
export const selectAccessTokenCreatedData = createSelector(selectUserAccessToken, state => state.accessTokenCreatedData);
export const selectAccessTokenCreating = createSelector(selectUserAccessToken, state => state.accessTokenCreating);
export const selectAccessTokenCreationError = createSelector(selectUserAccessToken, state => state.accessTokenCreationError);
export const selectAccessTokenIdsBeingDeleted = createSelector(selectUserAccessToken, state => state.accessTokenDeletionIds);
export const selectAccessTokenDeletionErrors = createSelector(selectUserAccessToken, state => state.accessTokenDeletionErrors);
export const selectTokenIdSelectedForDeletion = createSelector(selectUserAccessToken, state => state.tokenIdSelectedForDeletion);
