import { filterDictionary } from '@zaber/toolbox';
import _ from 'lodash';
import { ActionTypes as UserActionTypes } from '../user/actions';
import { changeCollection, createReducer } from '../utils';
import { SimulationState, SimulationType } from '../virtual_device_api';
import { ActionTypes } from './actions';
import { EntityKeyType, getKeyData } from './keys';
const initialState = {
    simulations: null,
    devices: null,
    axes: null,
    collapsedPrivateSimulations: {},
    privateSimulationsLoading: null,
    simulationProducts: null,
    simulationProductsLoading: null,
    chainBuilder: {
        name: 'Chain 1',
        isFinalizing: false,
        devices: null,
    },
    creatingSimulations: [],
    retryingCreation: null,
    retryingTurnOn: [],
    simulationCreationError: null,
    simulationsGettingRemoved: [],
    renameError: null,
    pollLock: 0,
    nextRetryId: 1,
};
const privateSimulationsLoading = (state) => (Object.assign(Object.assign({}, state), { privateSimulationsLoading: { state: 'loading' } }));
const simulationProductsLoading = (state) => (Object.assign(Object.assign({}, state), { simulationProductsLoading: { state: 'loading' } }));
const privateSimulationsLoaded = (state, { simulations, error }) => (Object.assign(Object.assign({}, state), { simulations: !simulations ? [] : (state.simulations || []).filter(sim => sim.type !== SimulationType.Private).concat(simulations), privateSimulationsLoading: error ? { state: 'error', error } : { state: 'loaded' } }));
const togglePrivateSimulationExpanded = (state, { simulationId }) => (Object.assign(Object.assign({}, state), { collapsedPrivateSimulations: Object.assign(Object.assign({}, state.collapsedPrivateSimulations), { [simulationId]: !state.collapsedPrivateSimulations[simulationId] }) }));
const simulationProductsLoaded = (state, { products, error }) => (Object.assign(Object.assign({}, state), { simulationProducts: products !== null && products !== void 0 ? products : null, simulationProductsLoading: error ? { state: 'error', error } : { state: 'loaded' } }));
const createSimulation = (state) => (Object.assign(Object.assign({}, state), { creatingSimulations: ['initializing'], renameError: null }));
const creatingSimulation = (state, { simulation }) => (Object.assign(Object.assign({}, state), { creatingSimulations: state.creatingSimulations.filter(id => id !== 'initializing').concat(simulation), simulationCreationError: null, retryingCreation: null }));
const simulationCreated = (state, { simulation, error }) => {
    var _a;
    return (Object.assign(Object.assign({}, state), { creatingSimulations: state.creatingSimulations.filter(sim => sim !== 'initializing' && sim.id !== (simulation === null || simulation === void 0 ? void 0 : simulation.id)), simulationCreationError: error !== null && error !== void 0 ? error : null, simulations: !simulation || ((_a = state.simulations) === null || _a === void 0 ? void 0 : _a.find(sim => sim.id === simulation.id))
            ? state.simulations
            : (state.simulations || [])
                .filter(sim => !(simulation.type === SimulationType.Public && sim.type === SimulationType.Public))
                .concat(simulation) }));
};
const retryCreateSimulation = (state, { productName, type }) => (Object.assign(Object.assign({}, state), { retryingCreation: { productName, type } }));
const cancelCreateRetry = (state) => (Object.assign(Object.assign({}, state), { retryingCreation: null }));
const renameSimulation = (state) => (Object.assign(Object.assign({}, state), { renameError: null }));
const renamedSimulation = (state, { simulationId, name }) => (Object.assign(Object.assign({}, state), { simulations: changeCollection(state.simulations, 'id', simulationId, sim => (Object.assign(Object.assign({}, sim), { name }))) }));
const storeRenameErr = (state, { error }) => (Object.assign(Object.assign({}, state), { renameError: error }));
const removeSimulation = (state, { simulationId }) => (!state.simulations ? state : Object.assign(Object.assign({}, state), { simulationsGettingRemoved: _.uniq(state.simulationsGettingRemoved.concat(simulationId)), renameError: null }));
const simulationRemoved = (state, { simulationId }) => (!state.simulations ? state : Object.assign(Object.assign({}, state), { simulationsGettingRemoved: state.simulationsGettingRemoved.filter(simId => simId !== simulationId), simulations: state.simulations.filter(simulation => simulation.id !== simulationId) }));
const updateSimulationState = (state, { simulationId, newState, statusMessage }) => (!state.simulations ? state : Object.assign(Object.assign({}, state), { simulations: changeCollection(state.simulations, 'id', simulationId, {
        state: newState,
        statusMessage,
    }) }));
const storeSimulationMetadata = (state, { simulation }) => {
    var _a;
    return (Object.assign(Object.assign({}, state), { simulationCreationError: null, creatingSimulations: state.creatingSimulations.filter(id => id !== simulation.id), simulations: ((_a = state.simulations) === null || _a === void 0 ? void 0 : _a.find(sim => sim.id === simulation.id))
            ? changeCollection(state.simulations, 'id', simulation.id, () => simulation)
            : (state.simulations || [])
                .filter(sim => !(simulation.type === SimulationType.Public && sim.type === SimulationType.Public))
                .concat(simulation) }));
};
const updateSimulationAxisLocations = (state, { simulationId, locations }) => {
    var _a;
    const simulation = (_a = state.simulations) === null || _a === void 0 ? void 0 : _a.find(sim => sim.id === simulationId);
    if (!(simulation === null || simulation === void 0 ? void 0 : simulation.axes) || !state.axes) {
        return state;
    }
    const axisKeys = simulation.axes.slice(0, locations.length);
    return Object.assign(Object.assign({}, state), { axes: Object.assign(Object.assign({}, state.axes), _.zipObject(axisKeys, axisKeys.map((key, i) => (Object.assign(Object.assign({}, state.axes[key]), { location: locations[i] }))))) });
};
const updateSimulationDevicesAndAxes = (state, { simulationId, devices, axes }) => (!state.simulations ? state : Object.assign(Object.assign({}, state), { simulations: changeCollection(state.simulations, 'id', simulationId, {
        devices: devices.map(device => device.key),
        axes: axes.map(axis => axis.key),
    }), devices: Object.assign(Object.assign({}, filterDictionary(state.devices, device => getKeyData(device.key)[EntityKeyType.SIMULATION] !== simulationId)), _.keyBy(devices, 'key')), axes: Object.assign(Object.assign({}, filterDictionary(state.axes, axis => getKeyData(axis.key)[EntityKeyType.SIMULATION] !== simulationId)), _.keyBy(axes, 'key')) }));
const updateSimulationError = (state, { simulationId, error }) => (!state.simulations ? state : Object.assign(Object.assign({}, state), { simulationsGettingRemoved: state.simulationsGettingRemoved.filter(simId => simId !== simulationId), simulations: changeCollection(state.simulations, 'id', simulationId, simulation => (Object.assign(Object.assign({}, simulation), { state: SimulationState.Error, error }))) }));
const clearSimulationError = (state, { simulationId }) => (!state.simulations ? state : Object.assign(Object.assign({}, state), { simulationsGettingRemoved: state.simulationsGettingRemoved.filter(simId => simId !== simulationId), simulations: changeCollection(state.simulations, 'id', simulationId, simulation => (Object.assign(Object.assign({}, simulation), { state: simulation.state === SimulationState.Error ? SimulationState.Off : simulation.state, error: undefined }))) }));
const updateSimulationExpiry = (state, { simulationId, expiry }) => (!state.simulations ? state : Object.assign(Object.assign({}, state), { simulations: changeCollection(state.simulations, 'id', simulationId, simulation => (Object.assign(Object.assign({}, simulation), { expires: expiry }))) }));
const retryTurnOn = (state, { simulationId }) => (Object.assign(Object.assign({}, state), { retryingTurnOn: state.retryingTurnOn
        .filter(({ id }) => id !== simulationId)
        .concat([{ id: simulationId, lastTryTime: performance.now() }]) }));
const cancelRetryTurnOn = (state, { simulationId }) => (Object.assign(Object.assign({}, state), { retryingTurnOn: state.retryingTurnOn.filter(({ id }) => id !== simulationId) }));
const chainBuilderStart = (state) => {
    var _a, _b;
    return (Object.assign(Object.assign({}, state), { chainBuilder: {
            name: `Chain ${((_b = (_a = state.simulations) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) + 1}`,
            isFinalizing: false,
            devices: [],
        } }));
};
const chainBuilderRename = (state, { name }) => (Object.assign(Object.assign({}, state), { chainBuilder: Object.assign(Object.assign({}, state.chainBuilder), { name }) }));
const chainBuilderCancel = (state) => (Object.assign(Object.assign({}, state), { chainBuilder: {
        name: '',
        isFinalizing: false,
        devices: null,
    }, retryingCreation: null }));
const chainBuilderAddDevice = (state) => (Object.assign(Object.assign({}, state), { chainBuilder: Object.assign(Object.assign({}, state.chainBuilder), { devices: [...state.chainBuilder.devices, {
                image: null,
                productName: '',
                params: {
                    deviceId: 0,
                    peripherals: undefined,
                }
            }] }) }));
const chainBuilderRemoveDevice = (state, { index }) => (Object.assign(Object.assign({}, state), { chainBuilder: Object.assign(Object.assign({}, state.chainBuilder), { devices: state.chainBuilder.devices.filter((_, i) => i !== index) }) }));
const replaceBuilderDevices = (state, devices) => (Object.assign(Object.assign({}, state), { chainBuilder: Object.assign(Object.assign({}, state.chainBuilder), { devices }) }));
const chainBuilderSelectDevice = (state, { index, product }) => {
    const devices = [...state.chainBuilder.devices];
    devices[index] = Object.assign(Object.assign({}, devices[index]), { productName: product });
    return replaceBuilderDevices(state, devices);
};
const chainBuilderSetImage = (state, { index, image }) => {
    // Fetching the image can take a while. It's possible (especially in tests) that the new device
    // chain has already been finalized by the time we have the result.
    if (!state.chainBuilder.devices || state.chainBuilder.devices.length <= index) {
        return state;
    }
    const devices = [...state.chainBuilder.devices];
    devices[index] = Object.assign(Object.assign({}, devices[index]), { image });
    return replaceBuilderDevices(state, devices);
};
const chainBuilderSetDeviceId = (state, { index, deviceId }) => {
    const devices = [...state.chainBuilder.devices];
    devices[index] = Object.assign(Object.assign({}, devices[index]), { params: Object.assign(Object.assign({}, devices[index].params), { deviceId }) });
    return replaceBuilderDevices(state, devices);
};
const chainBuilderSetFinalizing = (state, { isFinalizing }) => (Object.assign(Object.assign({}, state), { chainBuilder: Object.assign(Object.assign({}, state.chainBuilder), { isFinalizing }), retryingCreation: isFinalizing ? null : state.retryingCreation }));
const chainBuilderRetryFinalizing = (state) => (Object.assign(Object.assign({}, state), { retryingCreation: 'chain' }));
const resetState = (state) => (Object.assign(Object.assign({}, initialState), { simulationProducts: state.simulationProducts }));
const resetSimulationCreationError = (state) => (Object.assign(Object.assign({}, state), { simulationCreationError: null }));
const setPollLock = (state, { lockout }) => (Object.assign(Object.assign({}, state), { pollLock: state.pollLock + (lockout ? 1 : -1) }));
export const reducer = createReducer({
    [ActionTypes.LOAD_PRIVATE_SIMULATIONS]: privateSimulationsLoading,
    [ActionTypes.PRIVATE_SIMULATIONS_LOADED]: privateSimulationsLoaded,
    [ActionTypes.TOGGLE_PRIVATE_SIMULATION_EXPANDED]: togglePrivateSimulationExpanded,
    [ActionTypes.LOAD_SIMULATION_PRODUCTS]: simulationProductsLoading,
    [ActionTypes.SIMULATION_PRODUCTS_LOADED]: simulationProductsLoaded,
    [ActionTypes.CREATE_SIMULATION]: createSimulation,
    [ActionTypes.CREATING_SIMULATION]: creatingSimulation,
    [ActionTypes.SIMULATION_CREATED]: simulationCreated,
    [ActionTypes.RETRY_CREATE_SIMULATION]: retryCreateSimulation,
    [ActionTypes.CANCEL_CREATE_RETRY]: cancelCreateRetry,
    [ActionTypes.RENAME_SIMULATION]: renameSimulation,
    [ActionTypes.UPDATE_SIMULATION_NAME]: renamedSimulation,
    [ActionTypes.STORE_RENAME_ERROR]: storeRenameErr,
    [ActionTypes.REMOVE_SIMULATION]: removeSimulation,
    [ActionTypes.SIMULATION_REMOVED]: simulationRemoved,
    [ActionTypes.STORE_SIMULATION_METADATA]: storeSimulationMetadata,
    [ActionTypes.UPDATE_SIMULATION_STATE]: updateSimulationState,
    [ActionTypes.UPDATE_SIMULATION_AXIS_LOCATIONS]: updateSimulationAxisLocations,
    [ActionTypes.UPDATE_SIMULATION_DEVICES_AND_AXES]: updateSimulationDevicesAndAxes,
    [ActionTypes.UPDATE_SIMULATION_ERROR]: updateSimulationError,
    [ActionTypes.UPDATE_SIMULATION_EXPIRY]: updateSimulationExpiry,
    [ActionTypes.CLEAR_SIMULATION_ERROR]: clearSimulationError,
    [ActionTypes.RESET_SIMULATION_CREATION_ERROR]: resetSimulationCreationError,
    [ActionTypes.SET_POLL_LOCK]: setPollLock,
    [ActionTypes.RETRY_TURN_ON]: retryTurnOn,
    [ActionTypes.CANCEL_RETRY_TURN_ON]: cancelRetryTurnOn,
    [ActionTypes.CHAIN_BUILDER_START]: chainBuilderStart,
    [ActionTypes.CHAIN_BUILDER_RENAME]: chainBuilderRename,
    [ActionTypes.CHAIN_BUILDER_CANCEL]: chainBuilderCancel,
    [ActionTypes.CHAIN_BUILDER_ADD_DEVICE]: chainBuilderAddDevice,
    [ActionTypes.CHAIN_BUILDER_REMOVE_DEVICE]: chainBuilderRemoveDevice,
    [ActionTypes.CHAIN_BUILDER_SELECT_DEVICE]: chainBuilderSelectDevice,
    [ActionTypes.CHAIN_BUILDER_SET_IMAGE]: chainBuilderSetImage,
    [ActionTypes.CHAIN_BUILDER_SET_DEVICE_ID]: chainBuilderSetDeviceId,
    [ActionTypes.CHAIN_BUILDER_SET_FINALIZING]: chainBuilderSetFinalizing,
    [ActionTypes.CHAIN_BUILDER_RETRY_FINALIZE]: chainBuilderRetryFinalizing,
    [UserActionTypes.LOGIN]: resetState,
    [UserActionTypes.LOGOUT]: resetState,
}, initialState);
