import { extendKey } from '../virtual_device';
import { warningFlagMessages, warningFlagToErrorType } from './types';
export * from './VirtualDeviceViewer';
export { reducer as virtualDeviceViewerReducer } from './reducer';
export { actions as virtualDeviceViewerActions } from './actions';
export { virtualDeviceViewerSaga } from './sagas';
export function extendRootKeyUsingDeviceMessage(rootKey, message) {
    var _a;
    let key = rootKey;
    const match = (_a = message.match(/@(\d+)\s+(\d+)\s+.*/) // Replies
    ) !== null && _a !== void 0 ? _a : message.match(/!(\d+)\s+(\d+)+\s+.*/); // Alerts
    if (match) {
        key = extendKey(key, parseInt(match[1], 10));
        key = extendKey(key, parseInt(match[2], 10) || 1); // Treat integrated as axis 1 to match ZML.
    }
    return key;
}
export function extractFlagsFromDeviceMessage(message) {
    var _a;
    const match = (_a = message.match(/@\d+\s+\d+\s+(?:\d+\s+)?[A-Z]+\s+[A-Z]+\s+([A-Z-]{2})\s+.*/) // Replies
    ) !== null && _a !== void 0 ? _a : message.match(/!\d+\s+\d+\s+[A-Z]+\s+([A-Z-]{2}).*/); // Alerts
    if (match) {
        const flag = match[1];
        return (flag === '--') ? null : {
            type: warningFlagToErrorType(flag),
            warningFlag: flag,
            message: warningFlagMessages[flag],
        };
    }
    return null;
}
