import { actionBuilder } from '../utils';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["CHECK_SIMULATION"] = "VIRTUAL_DEVICE_VIEWER_CHECK_SIMULATION";
    ActionTypes["SIMULATION_CHECK_DONE"] = "VIRTUAL_DEVICE_VIEWER_SIMULATION_CHECK_DONE";
    ActionTypes["SETUP_SIMULATION"] = "VIRTUAL_DEVICE_VIEWER_SETUP_SIMULATION";
    ActionTypes["SIMULATION_SETUP_DONE"] = "VIRTUAL_DEVICE_VIEWER_SIMULATION_SETUP_DONE";
    ActionTypes["WEBSITE_PRODUCT_DATA_LOADED"] = "VIRTUAL_DEVICE_VIEWER_WEBSITE_PRODUCT_DATA_LOADED";
    ActionTypes["RESET_STATE"] = "VIRTUAL_DEVICE_VIEWER_RESET_STATE";
    ActionTypes["MOVE_SIMULATION_AXIS"] = "VIRTUAL_DEVICE_MOVE_SIMULATION_AXIS";
    ActionTypes["STORE_AXIS_WARNING_FLAGS"] = "VIRTUAL_DEVICE_STORE_AXIS_WARNING_FLAGS";
    ActionTypes["START_MONITORING_SIMULATION_POS"] = "VIRTUAL_DEVICE_VIEWER_START_MONITORING_SIMULATION_POS";
    ActionTypes["STOP_MONITORING_SIMULATION_POS"] = "VIRTUAL_DEVICE_VIEWER_STOP_MONITORING_SIMULATION_POS";
    ActionTypes["DEVICE_CLICKED"] = "VIRTUAL_DEVICE_VIEWER_DEVICE_CLICKED";
})(ActionTypes || (ActionTypes = {}));
const buildAction = (type, value) => actionBuilder(type, value);
export const actions = {
    checkSimulation: (simulationId) => buildAction(ActionTypes.CHECK_SIMULATION, { simulationId }),
    simulationCheckDone: (simulationId) => buildAction(ActionTypes.SIMULATION_CHECK_DONE, { simulationId }),
    simulationCheckErr: (error) => buildAction(ActionTypes.SIMULATION_CHECK_DONE, { error }),
    setupSimulation: (simulationId) => buildAction(ActionTypes.SETUP_SIMULATION, { simulationId }),
    simulationSetupDone: (simulationId) => buildAction(ActionTypes.SIMULATION_SETUP_DONE, { simulationId }),
    simulationSetupErr: (simulationId, error) => buildAction(ActionTypes.SIMULATION_SETUP_DONE, { simulationId, error }),
    websiteProductDataLoaded: (deviceId, productData) => buildAction(ActionTypes.WEBSITE_PRODUCT_DATA_LOADED, { deviceId, productData }),
    resetState: () => buildAction(ActionTypes.RESET_STATE),
    moveAxis: (axisKey, type) => buildAction(ActionTypes.MOVE_SIMULATION_AXIS, { axisKey, type }),
    storeAxisWarningFlags: (axisKey, warningFlags) => buildAction(ActionTypes.STORE_AXIS_WARNING_FLAGS, { axisKey, warningFlags }),
    startMonitoringSimulationPos: (simulationId) => buildAction(ActionTypes.START_MONITORING_SIMULATION_POS, { simulationId }),
    stopMonitoringSimulationPos: () => buildAction(ActionTypes.STOP_MONITORING_SIMULATION_POS),
    deviceClicked: (index) => buildAction(ActionTypes.DEVICE_CLICKED, { index }),
};
