import { WarningFlags } from '@zaber/motion/dist/lib/ascii';
export var ViewModes;
(function (ViewModes) {
    ViewModes[ViewModes["NoSimulationSelected"] = 0] = "NoSimulationSelected";
    ViewModes[ViewModes["Simulation"] = 1] = "Simulation";
    ViewModes[ViewModes["SimulationError"] = 2] = "SimulationError";
    ViewModes[ViewModes["SimulationRemoved"] = 3] = "SimulationRemoved";
    ViewModes[ViewModes["CreatingSimulation"] = 4] = "CreatingSimulation";
    ViewModes[ViewModes["CreationError"] = 5] = "CreationError";
    ViewModes[ViewModes["CheckingSimulation"] = 6] = "CheckingSimulation";
    ViewModes[ViewModes["CheckError"] = 7] = "CheckError";
})(ViewModes || (ViewModes = {}));
export var AxisWarningFlagType;
(function (AxisWarningFlagType) {
    AxisWarningFlagType[AxisWarningFlagType["Note"] = 0] = "Note";
    AxisWarningFlagType[AxisWarningFlagType["Warning"] = 1] = "Warning";
    AxisWarningFlagType[AxisWarningFlagType["Fault"] = 2] = "Fault";
    AxisWarningFlagType[AxisWarningFlagType["Unknown"] = 3] = "Unknown";
})(AxisWarningFlagType || (AxisWarningFlagType = {}));
export function warningFlagToErrorType(flag) {
    switch (flag[0]) {
        case 'N':
            return AxisWarningFlagType.Note;
        case 'W':
            return AxisWarningFlagType.Warning;
        case 'F':
        default:
            return AxisWarningFlagType.Fault;
    }
}
export const warningFlagMessages = {
    [WarningFlags.NO_REFERENCE_POSITION]: 'The axis does not have a reference position. This warning persists '
        + 'until the axis position is updated by homing or any command/action that sets position.',
    [WarningFlags.DEVICE_NOT_HOMED]: 'The axis has not been homed',
    [WarningFlags.MOVEMENT_INTERRUPTED]: 'Movement was interrupted',
    [WarningFlags.DRIVER_DISABLED]: 'The motor driver is disabled or has a fault',
    [WarningFlags.DRIVER_DISABLED_NO_FAULT]: 'The motor driver is disabled',
    [WarningFlags.CRITICAL_SYSTEM_ERROR]: 'Critical error - please help us diagnose the problem by sending the '
        + '"system errors" command and copying the terminal output into a feedback report.',
};
export const FIRST_TIME_HELP_KEY = 'ShowVirtualDeviceHelp';
