import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { userReducer } from '../user';
import { userAccessTokenReducer } from '../user_access_token';
import { virtualDeviceViewerReducer } from '../virtual_device_viewer';
import { virtualDeviceReducer } from '../virtual_device';
import { feedbackReducer } from '../feedback';
import { terminalReducer } from '../terminal';
import { demoReducer } from '../demo';
import { browserHistory } from './history';
export const rootReducer = combineReducers({
    router: connectRouter(browserHistory),
    user: userReducer,
    userAccessToken: userAccessTokenReducer,
    virtualDevice: virtualDeviceReducer,
    virtualDeviceViewer: virtualDeviceViewerReducer,
    feedback: feedbackReducer,
    terminal: terminalReducer,
    demo: demoReducer,
});
