import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from '@zaber/react-library';
import { useSelector } from 'react-redux';
import { useActions } from '@zaber/toolbox/lib/redux';
import { createSimulationViewerSimulationIdUrl } from '../urls';
import { browserHistory } from '../store/history';
import { AuthState } from '../user';
import { SimulationType } from '../virtual_device_api';
import { VirtualDeviceSelect, virtualDeviceActionDefinitions } from '../virtual_device';
import { selectAuthState } from '../user/selectors';
import { selectCreatingSimulations } from '../virtual_device/selectors';
import { VirtualDeviceId } from '../virtual_device/VirtualDeviceId';
import { virtualDeviceSelectionTypeForAuthState } from '../virtual_device/types';
import { selectCurrentSimulation, selectCurrentViewMode } from './selectors';
import { ViewModes } from './types';
export const SimulationSelectionBar = () => {
    const authState = useSelector(selectAuthState);
    const creatingSimulations = useSelector(selectCreatingSimulations);
    const currentSimulation = useSelector(selectCurrentSimulation);
    const viewMode = useSelector(selectCurrentViewMode);
    const virtualDeviceActions = useActions(virtualDeviceActionDefinitions);
    const checkingSimulation = viewMode === ViewModes.CheckingSimulation;
    let selectionValue = '';
    if (currentSimulation) {
        if (currentSimulation.type === SimulationType.Private) {
            selectionValue = currentSimulation.id;
        }
        else {
            selectionValue = currentSimulation.components[0].productName;
        }
    }
    return (_jsxs("div", Object.assign({ className: "options-bar" }, { children: [_jsx(VirtualDeviceSelect, { selectionItemType: virtualDeviceSelectionTypeForAuthState[authState], onValueChange: value => {
                    if (value) {
                        virtualDeviceActions.cancelCreateRetry();
                        if (authState === AuthState.Authenticated) {
                            browserHistory.push(createSimulationViewerSimulationIdUrl(value));
                        }
                        else if (authState === AuthState.NotAuthenticated) {
                            virtualDeviceActions.createSimulation(value, SimulationType.Public);
                        }
                    }
                }, disabled: (creatingSimulations.length > 0) || checkingSimulation, value: selectionValue }, `viewer-product-select-${selectionValue}`), _jsx("div", { className: "spacer" }, void 0), currentSimulation && _jsxs(_Fragment, { children: [_jsx(Text, Object.assign({ className: "device-id-label" }, { children: "Cloud ID:" }), void 0), _jsx(VirtualDeviceId, { virtualDevice: currentSimulation }, void 0)] }, void 0)] }), void 0));
};
