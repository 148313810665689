import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useActions } from '../utils';
import { AuthState, selectAuthState } from '../user';
import { selectPrivateSimulationsLoading, selectSimulationProductsLoading } from './selectors';
import { actions as actionsDefinition } from './actions';
export function useLoadPrivateSimulations() {
    const deviceActions = useActions(actionsDefinition);
    const authState = useSelector(selectAuthState);
    const userLoggedIn = authState === AuthState.Authenticated;
    const loadingState = useSelector(selectPrivateSimulationsLoading);
    const needsLoading = loadingState == null || loadingState.state === 'error';
    useEffect(() => {
        if (needsLoading) {
            deviceActions.loadPrivateSimulations();
        }
    }, [userLoggedIn, needsLoading]);
    return loadingState;
}
export function useLoadSimulationProducts() {
    const deviceActions = useActions(actionsDefinition);
    const loadingState = useSelector(selectSimulationProductsLoading);
    const needsLoading = loadingState == null || loadingState.state === 'error';
    useEffect(() => {
        if (needsLoading) {
            deviceActions.loadSimulationProducts();
        }
    }, [needsLoading]);
    return loadingState;
}
