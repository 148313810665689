import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Button, Input, Text } from '@zaber/react-library';
import { useEffect, useState } from 'react';
import { useActions } from '@zaber/toolbox/lib/redux';
import { IconMessage } from '../components/IconMessage';
import { TimedVisibility } from '../components/TimedVisibility';
import { useJustMounted } from '../app_components';
import { actions as actionDefinitions } from './actions';
import { OptionalAttributes } from './types';
import { selectLoggedInUserEmail, selectLoggedInUserFirstName, selectLoggedInUserLastName, selectUserAttributes } from './selectors';
export const UserAttributes = () => {
    var _a, _b;
    const userEmail = useSelector(selectLoggedInUserEmail);
    const userFirstName = (_a = useSelector(selectLoggedInUserFirstName)) !== null && _a !== void 0 ? _a : '';
    const [firstName, setFirstName] = useState('');
    useEffect(() => setFirstName(userFirstName), [userFirstName]);
    const userLastName = (_b = useSelector(selectLoggedInUserLastName)) !== null && _b !== void 0 ? _b : '';
    const [lastName, setLastName] = useState('');
    useEffect(() => setLastName(userLastName), [userLastName]);
    const { userAttributesUpdating, userAttributesUpdateError } = useSelector(selectUserAttributes);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const justMounted = useJustMounted();
    useEffect(() => {
        if (!justMounted && userAttributesUpdating && showConfirmation === false) {
            setShowConfirmation(true);
        }
    }, [userAttributesUpdating]);
    const actions = useActions(actionDefinitions);
    const localDataIsOutOfSync = (userFirstName !== firstName) || (userLastName !== lastName);
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "field" }, { children: [_jsx(Text, Object.assign({ e: Text.Emphasis.Bold }, { children: "Account Email" }), void 0), _jsx(Text, Object.assign({ className: "plain-text" }, { children: userEmail }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: "field" }, { children: [_jsx(Text, Object.assign({ e: Text.Emphasis.Bold }, { children: "First Name" }), void 0), _jsx(Input, { value: firstName, onChange: e => setFirstName(e.target.value) }, void 0)] }), void 0), _jsxs("div", Object.assign({ className: "field" }, { children: [_jsx(Text, Object.assign({ e: Text.Emphasis.Bold }, { children: "Last Name" }), void 0), _jsx(Input, { value: lastName, onChange: e => setLastName(e.target.value) }, void 0)] }), void 0), localDataIsOutOfSync && !userAttributesUpdating && _jsxs("div", Object.assign({ className: "save-attributes title-padded" }, { children: [_jsx(Button, Object.assign({ color: "grey", onClick: () => {
                            setShowConfirmation(false);
                            setFirstName(userFirstName);
                            setLastName(userLastName);
                        } }, { children: "Cancel" }), void 0), _jsx(Button, Object.assign({ onClick: () => actions.updateUserAttributes({
                            [OptionalAttributes.FirstName]: firstName,
                            [OptionalAttributes.LastName]: lastName
                        }) }, { children: "Save" }), void 0)] }), void 0), userAttributesUpdating && _jsx(IconMessage, Object.assign({ className: "title-padded", icon: "Gear", rotatingIcon: true }, { children: "Updating..." }), void 0), userAttributesUpdateError && showConfirmation && _jsxs(IconMessage, Object.assign({ className: "title-padded", icon: "ErrorWarning", color: "red" }, { children: ["There was an error. Please try again: ", userAttributesUpdateError] }), void 0), _jsx(TimedVisibility, Object.assign({ trigger: showConfirmation === true && !localDataIsOutOfSync, timeout: 5000, onTimeout: () => setShowConfirmation(false) }, { children: _jsx(IconMessage, Object.assign({ className: "title-padded", icon: "Confirmation", color: "green" }, { children: "Saved" }), void 0) }), void 0)] }, void 0));
};
