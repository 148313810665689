export const AVAILABLE_ACCESS_TOKEN_CREATED_BY_READABLE_NAME = {
    zaberLauncher: 'Zaber Launcher',
    virtualDevice: 'Virtual Device'
};
export const ACCESS_TOKEN_SCOPES_DISPLAY_DATA = {
    iot: {
        title: 'IoT',
        description: `Grants ability to interact with Cloud Shares and Virtual Devices using scripts,
    and to start Cloud Shares from the command line`,
    },
};
