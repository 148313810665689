export const connectContent = [
    {
        header: 'Choose from multiple connection types',
        content: 'Connect devices using serial ports, USB, Ethernet, or IoT.'
    },
    {
        header: 'Switch connections seamlessly',
        content: 'Take control of all your Zaber devices simultaneously with support for multiple parallel connections.'
    },
    {
        header: 'Collaborate Remotely',
        content: 'Share your connections over network or cloud.'
    }
];
export const moveContent = [
    {
        header: 'Try basic movements',
        content: 'Learn all the basic movement modes of your device\'s axes.'
    },
    {
        header: 'Program with G-Code',
        content: 'Tackle industrial applications using our G-Code translation application and API.'
    },
    {
        header: 'Monitor axis position',
        content: 'Monitor and control your devices during external script/application execution.'
    },
];
export const tuneContent = [
    {
        header: 'Change settings',
        content: `Adjust various settings like acceleration, speed,
    and stall recovery to meet your specific needs.`
    },
    {
        header: 'Optimize performance',
        content: `Tune the position controller using our Servo Tuner and Oscilloscope applications
    to achieve the best possible performance.`
    },
];
export const integrateContent = [
    {
        header: 'Enable human interaction',
        content: 'Configure the joystick\'s axes and buttons to create a great user experience.'
    },
    {
        header: 'Control third-party hardware',
        content: 'Integrate third-party hardware by controlling the device\'s I/O ports.'
    },
    {
        header: 'Customize hardware',
        content: 'Use our Advanced Hardware Setup application to modify your Zaber hardware or control third-party motors.'
    }
];
