import { actionBuilder } from '../utils';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["MONITOR_CONNECTION"] = "TERMINAL_MONITOR_CONNECTION";
    ActionTypes["SEND_COMMAND"] = "TERMINAL_SEND_COMMAND";
    ActionTypes["APPEND_TO_HISTORY"] = "TERMINAL_APPEND_TO_HISTORY";
    ActionTypes["APPEND_ERROR_TO_HISTORY"] = "TERMINAL_APPEND_ERROR_TO_HISTORY";
    ActionTypes["STOP_MONITORING"] = "TERMINAL_STOP_MONITORING";
    ActionTypes["CLEAR_CONNECTION_HISTORY"] = "TERMINAL_CLEAR_CONNECTION_HISTORY";
})(ActionTypes || (ActionTypes = {}));
const buildAction = (type, value) => actionBuilder(type, value);
export const actions = {
    monitorConnection: (simulationId) => buildAction(ActionTypes.MONITOR_CONNECTION, { simulationId }),
    sendCommand: (simulationId, command) => buildAction(ActionTypes.SEND_COMMAND, { simulationId, command }),
    appendToHistory: (simulationId, messages) => buildAction(ActionTypes.APPEND_TO_HISTORY, { simulationId, messages }),
    appendErrorToHistory: (simulationId, message) => buildAction(ActionTypes.APPEND_ERROR_TO_HISTORY, { simulationId, message }),
    stopMonitoring: () => buildAction(ActionTypes.STOP_MONITORING),
    clearConnectionHistory: (simulationId) => buildAction(ActionTypes.CLEAR_CONNECTION_HISTORY, { simulationId }),
};
