import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { Text } from '@zaber/react-library';
import { HistoryItemType } from './types';
export const TerminalHistoryItem = ({ historyItem }) => {
    const classes = classNames('history-item', {
        response: historyItem.type !== HistoryItemType.OtherRequest && historyItem.type !== HistoryItemType.LocalUserRequest,
        otherrequest: historyItem.type === HistoryItemType.OtherRequest,
        localuserrequest: historyItem.type === HistoryItemType.LocalUserRequest,
        error: historyItem.type === HistoryItemType.Error,
    });
    return _jsx(Text, Object.assign({ className: classes, f: Text.Family.Mono }, { children: historyItem.text }), void 0);
};
