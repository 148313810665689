import { createReducer } from '@zaber/toolbox/lib/redux';
import _ from 'lodash';
import { ActionTypes } from './actions';
const initialState = {
    accessTokens: null,
    accessTokensLoading: false,
    accessTokensLoadError: null,
    accessTokenCreateWanted: false,
    accessTokenCreating: false,
    accessTokenCreatedData: null,
    accessTokenCreationError: null,
    tokenIdSelectedForDeletion: null,
    accessTokenDeletionIds: [],
    accessTokenDeletionErrors: {},
};
const loadAccessTokens = (state) => (Object.assign(Object.assign({}, state), { accessTokensLoading: true, accessTokens: null, accessTokensLoadError: null }));
const accessTokensLoaded = (state, { tokens, error }) => (Object.assign(Object.assign({}, state), { accessTokensLoading: false, accessTokens: error ? null : (tokens !== null && tokens !== void 0 ? tokens : null), accessTokensLoadError: error !== null && error !== void 0 ? error : null }));
const updateAccessTokenCreateWanted = (state, { value }) => (Object.assign(Object.assign({}, state), { accessTokenCreateWanted: value }));
const createAccessToken = (state) => (Object.assign(Object.assign({}, state), { accessTokenCreating: true, accessTokenCreatedData: null, accessTokenCreationError: null }));
const accessTokenCreated = (state, { tokenId, token, error }) => (Object.assign(Object.assign({}, state), { accessTokenCreating: false, accessTokenCreatedData: (error || !tokenId || !token) ? null : { tokenId, token }, accessTokenCreationError: error !== null && error !== void 0 ? error : null }));
const clearCreatedAccessTokenData = (state) => (Object.assign(Object.assign({}, state), { accessTokenCreatedData: null }));
const deleteAccessToken = (state, { tokenId }) => (Object.assign(Object.assign({}, state), { accessTokenDeletionIds: state.accessTokenDeletionIds.concat(tokenId), accessTokenDeletionErrors: _.omit(state.accessTokenDeletionErrors, tokenId) }));
const accessTokenDeleted = (state, { tokenId, error }) => {
    var _a;
    return (Object.assign(Object.assign(Object.assign({}, state), { accessTokenDeletionIds: state.accessTokenDeletionIds.filter(id => id !== tokenId), accessTokenDeletionErrors: !(tokenId && error) ? state.accessTokenDeletionErrors : Object.assign(Object.assign({}, state.accessTokenDeletionErrors), { [tokenId]: error }), tokenIdSelectedForDeletion: error ? state.tokenIdSelectedForDeletion : null }), (((_a = state.accessTokenCreatedData) === null || _a === void 0 ? void 0 : _a.tokenId) === tokenId
        ? {
            accessTokenCreatedData: null,
            accessTokenCreateWanted: false,
        }
        : {})));
};
const setTokenIdSelectedForDeletion = (state, { tokenId }) => (Object.assign(Object.assign({}, state), { tokenIdSelectedForDeletion: tokenId }));
export const reducer = createReducer({
    [ActionTypes.LOAD_ACCESS_TOKENS]: loadAccessTokens,
    [ActionTypes.ACCESS_TOKENS_LOADED]: accessTokensLoaded,
    [ActionTypes.ACCESS_TOKEN_CREATE_WANTED]: updateAccessTokenCreateWanted,
    [ActionTypes.CREATE_ACCESS_TOKEN]: createAccessToken,
    [ActionTypes.ACCESS_TOKEN_CREATED]: accessTokenCreated,
    [ActionTypes.CLEAR_CREATED_ACCESS_TOKEN_DATA]: clearCreatedAccessTokenData,
    [ActionTypes.DELETE_ACCESS_TOKEN]: deleteAccessToken,
    [ActionTypes.ACCESS_TOKEN_DELETED]: accessTokenDeleted,
    [ActionTypes.CHOOSE_TOKEN_FOR_DELETION]: setTokenIdSelectedForDeletion,
}, initialState);
