import { useEffect, useState } from 'react';
export const TimedVisibility = ({ trigger, timeout, onTimeout, altComponent = null, children }) => {
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        if (!trigger) {
            return;
        }
        setVisible(true);
        const timeoutObj = setTimeout(() => {
            setVisible(false);
            onTimeout === null || onTimeout === void 0 ? void 0 : onTimeout();
        }, timeout);
        return () => clearTimeout(timeoutObj);
    }, [trigger]);
    if (visible) {
        return children;
    }
    return altComponent;
};
