import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '@zaber/toolbox/lib/redux';
import { AuthState } from './types';
import { actions as actionDefinitions } from './actions';
import { selectAuthState } from './selectors';
export const AuthGuard = ({ children }) => {
    const authState = useSelector(selectAuthState);
    const actions = useActions(actionDefinitions);
    useEffect(() => {
        if (authState === AuthState.NotAuthenticated) {
            actions.goToLogin();
        }
    }, [authState]);
    if (authState !== AuthState.Authenticated) {
        return null;
    }
    return children;
};
