import { actionBuilder } from '../utils';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["LOAD_PRIVATE_SIMULATIONS"] = "VIRTUAL_DEVICE_LOAD_PRIVATE_SIMULATIONS";
    ActionTypes["PRIVATE_SIMULATIONS_LOADED"] = "VIRTUAL_DEVICE_PRIVATE_SIMULATIONS_LOADED";
    ActionTypes["TOGGLE_PRIVATE_SIMULATION_EXPANDED"] = "VIRTUAL_DEVICE_TOGGLE_PRIVATE_SIMULATION_EXPANDED";
    ActionTypes["LOAD_SIMULATION_PRODUCTS"] = "VIRTUAL_DEVICE_LOAD_SIMULATION_PRODUCTS";
    ActionTypes["SIMULATION_PRODUCTS_LOADED"] = "VIRTUAL_DEVICE_SIMULATION_PRODUCTS_LOADED";
    ActionTypes["CREATE_SIMULATION"] = "VIRTUAL_DEVICE_CREATE_SIMULATION";
    ActionTypes["CREATING_SIMULATION"] = "VIRTUAL_DEVICE_CREATING_SIMULATION";
    ActionTypes["SIMULATION_CREATED"] = "VIRTUAL_DEVICE_SIMULATION_CREATED";
    ActionTypes["RETRY_CREATE_SIMULATION"] = "VIRTUAL_DEVICE_RETRY_CREATE_SIMULATION";
    ActionTypes["CANCEL_CREATE_RETRY"] = "VIRTUAL_DEVICE_CANCEL_CREATE_RETRY";
    ActionTypes["RENAME_SIMULATION"] = "VIRTUAL_DEVICE_RENAME_SIMULATION";
    ActionTypes["UPDATE_SIMULATION_NAME"] = "VIRTUAL_DEVICE_UPDATE_SIMULATION_NAME";
    ActionTypes["STORE_RENAME_ERROR"] = "VIRTUAL_DEVICE_STORE_RENAME_ERROR";
    ActionTypes["REMOVE_SIMULATION"] = "VIRTUAL_DEVICE_REMOVE_SIMULATION";
    ActionTypes["SIMULATION_REMOVED"] = "VIRTUAL_DEVICE_SIMULATION_REMOVED";
    ActionTypes["TURN_ON_SIMULATION"] = "VIRTUAL_DEVICE_TURN_ON_SIMULATION";
    ActionTypes["TURN_OFF_SIMULATION"] = "VIRTUAL_DEVICE_TURN_OFF_SIMULATION";
    ActionTypes["RETRY_TURN_ON"] = "VIRTUAL_DEVICE_RETRY_TURN_ON";
    ActionTypes["CANCEL_RETRY_TURN_ON"] = "VIRTUAL_DEVICE_CANCEL_RETRY_TURN_ON";
    ActionTypes["STORE_SIMULATION_METADATA"] = "VIRTUAL_DEVICE_STORE_SIMULATION_METADATA";
    ActionTypes["UPDATE_SIMULATION_STATE"] = "VIRTUAL_DEVICE_UPDATE_SIMULATION_STATE";
    ActionTypes["UPDATE_SIMULATION_AXIS_LOCATIONS"] = "VIRTUAL_DEVICE_UPDATE_SIMULATION_AXIS_LOCATIONS";
    ActionTypes["UPDATE_SIMULATION_DEVICES_AND_AXES"] = "VIRTUAL_DEVICE_UPDATE_SIMULATION_DEVICES_AND_AXES";
    ActionTypes["UPDATE_SIMULATION_ERROR"] = "VIRTUAL_DEVICE_UPDATE_SIMULATION_ERROR";
    ActionTypes["CLEAR_SIMULATION_ERROR"] = "VIRTUAL_DEVICE_CLEAR_SIMULATION_ERROR";
    ActionTypes["UPDATE_SIMULATION_EXPIRY"] = "VIRTUAL_DEVICE_UPDATE_SIMULATION_EXPIRY";
    ActionTypes["START_MONITORING"] = "VIRTUAL_DEVICE_START_MONITORING";
    ActionTypes["STOP_MONITORING"] = "VIRTUAL_DEVICE_STOP_MONITORING";
    ActionTypes["RESET_SIMULATION_CREATION_ERROR"] = "VIRTUAL_DEVICE_RESET_SIMULATION_CREATION_ERROR";
    ActionTypes["SET_POLL_LOCK"] = "VIRTUAL_DEVICE_SET_POLL_LOCK";
    ActionTypes["CHAIN_BUILDER_START"] = "VIRTUAL_DEVICE_CHAIN_BUILDER_START";
    ActionTypes["CHAIN_BUILDER_RENAME"] = "VIRTUAL_DEVICE_CHAIN_BUILDER_RENAME";
    ActionTypes["CHAIN_BUILDER_CANCEL"] = "VIRTUAL_DEVICE_CHAIN_BUILDER_CANCEL";
    ActionTypes["CHAIN_BUILDER_ADD_DEVICE"] = "VIRTUAL_DEVICE_CHAIN_BUILDER_ADD_DEVICE";
    ActionTypes["CHAIN_BUILDER_REMOVE_DEVICE"] = "VIRTUAL_DEVICE_CHAIN_BUILDER_REMOVE_DEVICE";
    ActionTypes["CHAIN_BUILDER_SELECT_DEVICE"] = "VIRTUAL_DEVICE_CHAIN_BUILDER_SELECT_DEVICE";
    ActionTypes["CHAIN_BUILDER_SET_IMAGE"] = "VIRTUAL_DEVICE_CHAIN_BUILDER_SET_IMAGE";
    ActionTypes["CHAIN_BUILDER_SET_DEVICE_ID"] = "VIRTUAL_DEVICE_CHAIN_BUILDER_SET_DEVICE_ID";
    ActionTypes["CHAIN_BUILDER_FINALIZE"] = "VIRTUAL_DEVICE_CHAIN_BUILDER_FINALIZE";
    ActionTypes["CHAIN_BUILDER_SET_FINALIZING"] = "VIRTUAL_DEVICE_CHAIN_BUILDER_SET_FINALIZING";
    ActionTypes["CHAIN_BUILDER_RETRY_FINALIZE"] = "VIRTUAL_DEVICE_CHAIN_BUILDER_RETRY_FINALIZE";
})(ActionTypes || (ActionTypes = {}));
const buildAction = (type, value) => actionBuilder(type, value);
export const actions = {
    loadPrivateSimulations: () => buildAction(ActionTypes.LOAD_PRIVATE_SIMULATIONS),
    privateSimulationsLoaded: (simulations) => buildAction(ActionTypes.PRIVATE_SIMULATIONS_LOADED, { simulations }),
    privateSimulationsLoadedErr: (error) => buildAction(ActionTypes.PRIVATE_SIMULATIONS_LOADED, { error }),
    togglePrivateSimulationExpanded: (simulationId) => buildAction(ActionTypes.TOGGLE_PRIVATE_SIMULATION_EXPANDED, { simulationId }),
    loadSimulationProducts: () => buildAction(ActionTypes.LOAD_SIMULATION_PRODUCTS),
    simulationProductsLoaded: (products) => buildAction(ActionTypes.SIMULATION_PRODUCTS_LOADED, { products }),
    simulationProductsLoadErr: (error) => buildAction(ActionTypes.SIMULATION_PRODUCTS_LOADED, { error }),
    createSimulation: (productName, type) => buildAction(ActionTypes.CREATE_SIMULATION, { productName, type }),
    creatingSimulation: (simulation) => buildAction(ActionTypes.CREATING_SIMULATION, { simulation }),
    simulationCreated: (simulation) => buildAction(ActionTypes.SIMULATION_CREATED, { simulation }),
    simulationCreationErr: (error) => buildAction(ActionTypes.SIMULATION_CREATED, { error }),
    retryCreateSimulation: (productName, type) => buildAction(ActionTypes.RETRY_CREATE_SIMULATION, { productName, type }),
    cancelCreateRetry: () => buildAction(ActionTypes.CANCEL_CREATE_RETRY),
    renameSimulation: (simulationId, name) => buildAction(ActionTypes.RENAME_SIMULATION, { simulationId, name }),
    updateSimulationName: (simulationId, name) => buildAction(ActionTypes.UPDATE_SIMULATION_NAME, { simulationId, name }),
    storeRenameError: (error) => buildAction(ActionTypes.STORE_RENAME_ERROR, { error }),
    removeSimulation: (simulationId) => buildAction(ActionTypes.REMOVE_SIMULATION, { simulationId }),
    simulationRemoved: (simulationId) => buildAction(ActionTypes.SIMULATION_REMOVED, { simulationId }),
    turnOnSimulation: (simulationId) => buildAction(ActionTypes.TURN_ON_SIMULATION, { simulationId }),
    turnOffSimulation: (simulationId) => buildAction(ActionTypes.TURN_OFF_SIMULATION, { simulationId }),
    retryTurnOn: (simulationId) => buildAction(ActionTypes.RETRY_TURN_ON, { simulationId }),
    cancelRetryTurnOn: (simulationId) => buildAction(ActionTypes.CANCEL_RETRY_TURN_ON, { simulationId }),
    storeSimulationMetadata: (simulation) => buildAction(ActionTypes.STORE_SIMULATION_METADATA, { simulation }),
    updateSimulationState: (simulationId, newState, statusMessage) => buildAction(ActionTypes.UPDATE_SIMULATION_STATE, { simulationId, newState, statusMessage }),
    updateSimulationAxisLocations: (simulationId, locations) => buildAction(ActionTypes.UPDATE_SIMULATION_AXIS_LOCATIONS, { simulationId, locations }),
    updateSimulationDevicesAndAxes: (simulationId, devices, axes) => buildAction(ActionTypes.UPDATE_SIMULATION_DEVICES_AND_AXES, { simulationId, devices, axes }),
    updateSimulationError: (simulationId, error) => buildAction(ActionTypes.UPDATE_SIMULATION_ERROR, { simulationId, error }),
    updateSimulationExpiry: (simulationId, expiry) => buildAction(ActionTypes.UPDATE_SIMULATION_EXPIRY, { simulationId, expiry }),
    clearSimulationError: (simulationId) => buildAction(ActionTypes.CLEAR_SIMULATION_ERROR, { simulationId }),
    startMonitoring: () => buildAction(ActionTypes.START_MONITORING),
    stopMonitoring: () => buildAction(ActionTypes.STOP_MONITORING),
    resetSimulationCreationError: () => buildAction(ActionTypes.RESET_SIMULATION_CREATION_ERROR),
    setPollLock: (lockout) => buildAction(ActionTypes.SET_POLL_LOCK, { lockout }),
    chainBuilderStart: () => buildAction(ActionTypes.CHAIN_BUILDER_START),
    chainBuilderRename: (name) => buildAction(ActionTypes.CHAIN_BUILDER_RENAME, { name }),
    chainBuilderCancel: () => buildAction(ActionTypes.CHAIN_BUILDER_CANCEL),
    chainBuilderAddDevice: () => buildAction(ActionTypes.CHAIN_BUILDER_ADD_DEVICE),
    chainBuilderRemoveDevice: (index) => buildAction(ActionTypes.CHAIN_BUILDER_REMOVE_DEVICE, { index }),
    chainBuilderSelectDevice: (index, product) => buildAction(ActionTypes.CHAIN_BUILDER_SELECT_DEVICE, { index, product }),
    chainBuilderSetImage: (index, image) => buildAction(ActionTypes.CHAIN_BUILDER_SET_IMAGE, { index, image }),
    chainBuilderSetDeviceId: (index, deviceId) => buildAction(ActionTypes.CHAIN_BUILDER_SET_DEVICE_ID, { index, deviceId }),
    chainBuilderFinalize: () => buildAction(ActionTypes.CHAIN_BUILDER_FINALIZE),
    chainBuilderSetFinalizing: (isFinalizing) => buildAction(ActionTypes.CHAIN_BUILDER_SET_FINALIZING, { isFinalizing }),
    chainBuilderRetryFinalize: () => buildAction(ActionTypes.CHAIN_BUILDER_RETRY_FINALIZE),
};
