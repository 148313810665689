import _ from 'lodash';
import { all, call, put, take, takeEvery, race, takeLeading, select } from 'redux-saga/effects';
import { getContainer } from '../container';
import { makeRootKey } from '../virtual_device';
import { VirtualDevicesService } from '../virtual_device_connection';
import { extendRootKeyUsingDeviceMessage, extractFlagsFromDeviceMessage, virtualDeviceViewerActions } from '../virtual_device_viewer';
import { selectIotRealm } from '../user/selectors';
import { userIsLoggedIn } from '../user';
import { throwIfJsError, rxToEventChannel } from '../utils';
import { ActionTypes, actions } from './actions';
export function* terminalSaga() {
    yield all([
        takeLeading(ActionTypes.MONITOR_CONNECTION, monitorMessages),
        takeEvery(ActionTypes.SEND_COMMAND, sendCommand),
    ]);
}
function* monitorMessages({ payload: { simulationId } }) {
    try {
        const virtualDevicesService = getContainer().get(VirtualDevicesService);
        const terminalMessages = yield call([virtualDevicesService, virtualDevicesService.getConnectionMessages], simulationId);
        yield call(monitorConnectionMessages, simulationId, terminalMessages);
    }
    catch (err) {
        throwIfJsError(err);
    }
}
function* sendCommand({ payload: { command, simulationId } }) {
    let realm = 'unauthenticated';
    const isLoggedIn = yield call(userIsLoggedIn);
    if (isLoggedIn) {
        realm = yield select(selectIotRealm);
    }
    try {
        const virtualDevicesService = getContainer().get(VirtualDevicesService);
        const terminalRoutedConnection = yield call([virtualDevicesService, virtualDevicesService.getRoutedConnection], simulationId, realm);
        yield call([terminalRoutedConnection, terminalRoutedConnection.sendMessage], command);
    }
    catch (err) {
        throwIfJsError(err);
        yield put(actions.appendErrorToHistory(simulationId, String(err)));
    }
}
function* monitorConnectionMessages(simulationId, observable) {
    const channel = rxToEventChannel(observable);
    const simulationKey = makeRootKey(simulationId);
    try {
        while (true) {
            const { data } = yield race({
                data: take(channel),
                stop: take(ActionTypes.STOP_MONITORING),
            });
            if (!data) {
                break;
            }
            const messages = data;
            yield put(actions.appendToHistory(simulationId, messages));
            // Notify the viewer display of flags on the latest reply or alert from each axis.
            const lastResponsePerDevice = _.reduce(messages.filter(msg => !msg.source.startsWith('cmd')), (acc, msg) => {
                const axisKey = extendRootKeyUsingDeviceMessage(simulationKey, msg.message);
                acc[axisKey] = msg.message;
                return acc;
            }, {});
            for (const [axisKey, lastResponse] of Object.entries(lastResponsePerDevice)) {
                const warning = extractFlagsFromDeviceMessage(lastResponse);
                yield put(virtualDeviceViewerActions.storeAxisWarningFlags(axisKey, warning ? [warning] : []));
            }
        }
    }
    finally {
        channel.close();
    }
}
