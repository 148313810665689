import { SimulationState } from '@zaber/serverless-service-definitions';
import { AuthState } from '../user';
export function locationToPosition(location, resolution) {
    // Firmware reports location over IoT, which is
    // location = pos * 2^14 / resolution
    return Number((location * BigInt(resolution)) >> BigInt(14));
}
export function positionToLocation(position, resolution) {
    return BigInt(position * (1 << 14) / resolution);
}
export var SelectionItemTypes;
(function (SelectionItemTypes) {
    SelectionItemTypes[SelectionItemTypes["SimulationProducts"] = 0] = "SimulationProducts";
    SelectionItemTypes[SelectionItemTypes["PrivateSimulations"] = 1] = "PrivateSimulations";
    SelectionItemTypes[SelectionItemTypes["None"] = 2] = "None";
})(SelectionItemTypes || (SelectionItemTypes = {}));
export const virtualDeviceSelectionTypeForAuthState = {
    [AuthState.Authenticated]: SelectionItemTypes.PrivateSimulations,
    [AuthState.NotAuthenticated]: SelectionItemTypes.SimulationProducts,
    [AuthState.Checking]: SelectionItemTypes.None,
};
export const MAX_POLL_INTERVAL = 60000;
export const DEFAULT_POLL_INTERVAL = 5000;
export const RETRY_INTERVAL = 30000;
export const pollIntervalsForSimState = {
    [SimulationState.Creating]: 5000,
    [SimulationState.On]: 10000,
    [SimulationState.TurningOn]: 3000,
    [SimulationState.Off]: 10000,
    [SimulationState.TurningOff]: 1000,
    [SimulationState.Deleting]: 1000,
    [SimulationState.Deleted]: MAX_POLL_INTERVAL,
    [SimulationState.Error]: 10000,
};
