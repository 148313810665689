export var AuthState;
(function (AuthState) {
    AuthState[AuthState["Authenticated"] = 0] = "Authenticated";
    AuthState[AuthState["Checking"] = 1] = "Checking";
    AuthState[AuthState["NotAuthenticated"] = 2] = "NotAuthenticated";
})(AuthState || (AuthState = {}));
export var CustomAttributes;
(function (CustomAttributes) {
    CustomAttributes["IdentityId"] = "custom:identity_id";
})(CustomAttributes || (CustomAttributes = {}));
export var OptionalAttributes;
(function (OptionalAttributes) {
    OptionalAttributes["FirstName"] = "given_name";
    OptionalAttributes["LastName"] = "family_name";
})(OptionalAttributes || (OptionalAttributes = {}));
export const INCORRECT_PASSWORD_ERROR = 'Incorrect username or password.';
export const EMAIL_NOT_CONFIRMED_ERROR = 'User is not confirmed.';
export const PRODUCTION_ENV_PASSWORD_VALIDATION_REQUIREMENTS = [{
        text: '8 characters',
        validator: password => password.length >= 8,
    }];
export const NON_PRODUCTION_ENV_PASSWORD_VALIDATION_REQUIREMENTS = [{
        text: '6 characters',
        validator: password => password.length >= 6,
    }];
export const PASSWORD_AGAIN_VALIDATION_REQUIREMENTS = [{
        text: 'Passwords match',
        validator: (password, passwordAgain) => !!password && password === passwordAgain,
    }];
