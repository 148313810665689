import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { NavigationBar } from '../navigation_bar';
import { PageTitle } from '../components/PageTitle';
const CONTENT_PREFIX = '/dev-portal';
function translatePathBack(location) {
    return location.pathname.replace(CONTENT_PREFIX, '').replace(/\/$/, '') + location.hash;
}
function translatePathForward(path) {
    return `${CONTENT_PREFIX}${path}`;
}
/**
 * This component uses iframe to display content and synchronizes it to the top-level URL.
 * The iframe is located outside of the component to make browser correctly preserve history.
 */
export const ZML = () => {
    const location = useLocation();
    const pathnameRef = useRef('');
    pathnameRef.current = location.pathname + location.hash;
    const history = useHistory();
    const root = useRef(null);
    const [title, setTitle] = useState('Zaber Motion Library');
    const iframe = useMemo(() => {
        const iframe = document.querySelector('iframe.zml-frame');
        if (iframe == null) {
            throw new Error('ZML iframe not found');
        }
        return iframe;
    }, []);
    const iframeWindow = iframe.contentWindow;
    useLayoutEffect(() => {
        const syncTopPath = () => {
            const pathname = translatePathBack(iframeWindow.location);
            if (pathnameRef.current !== pathname) {
                history.replace(pathname);
            }
        };
        const onFrameLoad = () => {
            setTitle(iframeWindow.document.title);
            syncTopPath();
            // for some reason hashchange must be re-registered every time
            iframeWindow.addEventListener('hashchange', syncTopPath);
        };
        iframe.addEventListener('load', onFrameLoad);
        iframe.hidden = false;
        return () => {
            iframe.hidden = true;
            iframe.removeEventListener('load', onFrameLoad);
        };
    }, []);
    useLayoutEffect(() => {
        const framePathname = translatePathBack(iframeWindow.location);
        if (framePathname !== pathnameRef.current) {
            iframeWindow.location.replace(translatePathForward(pathnameRef.current));
        }
    }, [pathnameRef.current]);
    return _jsxs("div", Object.assign({ className: "zml-page", ref: root }, { children: [_jsx(NavigationBar, { responsive: true }, void 0), _jsx(PageTitle, { children: title }, void 0)] }), void 0);
};
