var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { observeOn, take } from 'rxjs/operators';
import { asapScheduler, merge, ReplaySubject, Subject } from 'rxjs';
import { handleUnexpectedError } from '../errors';
import { getContainer } from '../container';
import { RoutedConnection, MonitorConnection, RpcClient, RpcNotifications, MessageRouterApi, Log, RpcIoIoT, IoTService, } from '../app_components';
export const CHECK_UNRELIABLE_CONNECTION_STATUS = 30 * 1000;
export class MessageRouterConnection {
    constructor(simulationId, realm) {
        this.simulationId = simulationId;
        this.realm = realm;
        this.connected = false;
        this.lastStatusCheckTs = 0;
        this.checkStatusInterval = null;
        this.ownErrors = new Subject();
        this.endedSubject = new ReplaySubject();
        this.ended = this.endedSubject;
        this.newMonitorConnectionSubject = new Subject();
        this.newMonitorConnection = this.newMonitorConnectionSubject.pipe(observeOn(asapScheduler));
        this.log = getContainer().get(Log).getLogger(`MessageRouter(${simulationId})`);
        this.io = new RpcIoIoT(getContainer().get(IoTService), {
            routerId: simulationId,
            realm,
        });
        this.rpc = new RpcClient(this.io, performance);
        this.rpc.handlerErrors.subscribe(handleUnexpectedError);
        this.notifications = new RpcNotifications(this.rpc);
        this.api = new MessageRouterApi(this.rpc);
        this.errors = merge(this.ownErrors, this.rpc.errors, this.io.errors).pipe(take(1));
    }
    ensureConnected() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.connected) {
                return;
            }
            if (this.connecting) {
                yield this.connecting;
                return;
            }
            this.connecting = this.connect();
            yield this.connecting;
            this.connected = true;
        });
    }
    close() {
        return __awaiter(this, void 0, void 0, function* () {
            this.rpc.end();
            yield this.io.close();
            if (this.checkStatusInterval !== null) {
                clearInterval(this.checkStatusInterval);
                this.checkStatusInterval = null;
            }
            this.endedSubject.next(true);
            this.endedSubject.complete();
            this.newMonitorConnectionSubject.complete();
        });
    }
    getRoutedConnection() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.routedConnection == null) {
                this.routedConnection = new RoutedConnection(this.connectionId, this.rpc, this.notifications);
                this.routedConnection.ended.subscribe(() => this.routedConnection = null);
            }
            yield this.routedConnection.ensureOpen();
            if (this.monitorConnection == null) {
                this.monitorConnection = new MonitorConnection(this.connectionId, this.rpc, this.notifications);
                this.monitorConnection.monitorMessages.subscribe({
                    complete: () => this.monitorConnection = null,
                    error: () => this.monitorConnection = null,
                });
                this.newMonitorConnectionSubject.next(this.monitorConnection);
                yield this.monitorConnection.start();
            }
            return this.routedConnection;
        });
    }
    getConnectionId() {
        return __awaiter(this, void 0, void 0, function* () {
            const connections = yield this.api.getConnections();
            if (connections.length < 1) {
                throw Error('Expected at least one connection');
            }
            return connections[0].id;
        });
    }
    connect() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.io.connect();
            yield this.api.ensureCompatibility();
            if (!this.io.isReliable) {
                this.checkStatusInterval = setInterval(this.onCheckStatus.bind(this), CHECK_UNRELIABLE_CONNECTION_STATUS);
            }
            this.connectionId = yield this.getConnectionId();
        });
    }
    onCheckStatus() {
        return __awaiter(this, void 0, void 0, function* () {
            const isActive = this.io.lastMessageTs > this.lastStatusCheckTs || this.notifications.hasSubscriptions();
            if (!isActive) {
                return;
            }
            try {
                yield this.rpc.call('status_ping');
            }
            catch (err) {
                this.log.warn(`Cannot get status: ${err}`);
                this.ownErrors.next(err);
            }
            this.lastStatusCheckTs = this.io.lastMessageTs;
        });
    }
    getStandaloneRoutedConnection(connectionId) {
        return __awaiter(this, void 0, void 0, function* () {
            const connection = new RoutedConnection(connectionId, this.rpc, this.notifications);
            yield connection.ensureOpen();
            return connection.connection;
        });
    }
}
