var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Select from 'react-select';
import { Icons, Text } from '@zaber/react-library';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import classNames from 'classnames';
import { SimulationState } from '../virtual_device_api';
import { createSimulationViewerSimulationIdUrl } from '../urls';
import { NewTabLink } from '../components/Links';
import { SelectionItemTypes } from './types';
import { selectPrivateSimulations, selectPrivateSimulationsLoading } from './selectors';
import { VirtualDeviceProductSelector } from './VirtualDeviceProductSelector';
import { selectStyles } from './VirtualDeviceSelectTypes';
export const PrivateSimulationLabel = ({ label, disabled = false, state, value }) => {
    const simulationIsOn = [SimulationState.On, SimulationState.TurningOn].includes(state);
    return _jsxs("div", Object.assign({ className: "option-container" }, { children: [_jsx(Text, Object.assign({ t: Text.Type.Body }, { children: label }), void 0), !disabled && _jsxs("div", Object.assign({ className: "icon-container" }, { children: [simulationIsOn
                        ? _jsx(Icons.BulletPoint, { className: "active-icon", title: "Device running" }, void 0)
                        : _jsx(Icons.DotHollow, { className: "inactive-icon", title: "Device off" }, void 0), _jsx("div", Object.assign({ onClick: e => e.stopPropagation() }, { children: _jsx(NewTabLink, Object.assign({ to: createSimulationViewerSimulationIdUrl(value) }, { children: _jsx(Icons.NewWindow, { className: "new-window-icon", title: "Open device in a new tab" }, void 0) }), void 0) }), void 0)] }), void 0)] }), void 0);
};
export const PrivateDeviceSelect = (_a) => {
    var { disabled, value, onValueChange, className } = _a, rest = __rest(_a, ["disabled", "value", "onValueChange", "className"]);
    const privateSimulationsLoading = useSelector(selectPrivateSimulationsLoading);
    const privateSimulations = useSelector(selectPrivateSimulations);
    const simOptions = useMemo(() => {
        var _a;
        return (_a = privateSimulations === null || privateSimulations === void 0 ? void 0 : privateSimulations.map(sim => ({ value: sim.id, label: sim.name, state: sim.state }))) !== null && _a !== void 0 ? _a : [];
    }, [privateSimulations]);
    const isLoading = (privateSimulationsLoading === null || privateSimulationsLoading === void 0 ? void 0 : privateSimulationsLoading.state) === 'loading';
    const hasError = (privateSimulationsLoading === null || privateSimulationsLoading === void 0 ? void 0 : privateSimulationsLoading.state) === 'error';
    return _jsx("div", Object.assign({ className: "virtual-device-simulation-select" }, { children: _jsx(Select, Object.assign({ className: classNames('virtual-device-select', className), classNamePrefix: "vd", isDisabled: isLoading || hasError || disabled, isOptionDisabled: option => option.disabled === true, placeholder: isLoading ? 'Loading...' : (hasError ? 'Error while loading devices' : 'Select a device'), onChange: option => onValueChange === null || onValueChange === void 0 ? void 0 : onValueChange(option.value), "data-testid": "virtual-device-select", options: simOptions, formatOptionLabel: PrivateSimulationLabel, value: simOptions.find(option => option.value === value), styles: selectStyles }, rest), void 0) }), void 0);
};
export const VirtualDeviceSelect = (_a) => {
    var { selectionItemType } = _a, rest = __rest(_a, ["selectionItemType"]);
    switch (selectionItemType) {
        case SelectionItemTypes.PrivateSimulations:
            return _jsx(PrivateDeviceSelect, Object.assign({}, rest), void 0);
        case SelectionItemTypes.SimulationProducts:
            return _jsx(VirtualDeviceProductSelector, Object.assign({}, rest), void 0);
        default:
            return null;
    }
};
