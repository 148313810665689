import 'reflect-metadata';
import { Container } from 'inversify';
import { LocalStorageSymbol } from '@zaber/app-components/lib/storage';
import { environment } from './environment';
let container;
export function getContainer() {
    if (environment.isTest && !container) {
        throw Error('Container has not been created yet. Please create the container first');
    }
    return container !== null && container !== void 0 ? container : createContainer();
}
export function createContainer() {
    container = new Container({ autoBindInjectable: true, defaultScope: 'Singleton' });
    if (global.localStorage) {
        container.bind(LocalStorageSymbol).toConstantValue(global.localStorage);
    }
    return container;
}
export function destroyContainer() {
    if (container) {
        container.unbindAll();
        container = null;
    }
}
