var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { injectable, inject } from 'inversify';
import { asapScheduler, Subject } from 'rxjs';
import { observeOn } from 'rxjs/operators';
import { DeviceDbSourceType, Library } from '@zaber/motion';
import zmlWasmFile from '@zaber/motion/dist/binding/wasm/zaber-motion-lib.wasm';
import * as zmlWasm from '@zaber/motion/dist/lib/wasm';
import { environment } from '../environment';
import { handleUnexpectedError } from '../errors';
import { Log } from '../app_components';
import { MessageRouterConnection } from './message_router_connection';
let VirtualDevicesService = class VirtualDevicesService {
    constructor(logService) {
        this.connections = new Map();
        this.newConnectionsSubject = new Subject();
        this.newConnections = this.newConnectionsSubject.pipe(observeOn(asapScheduler));
        this.connectionMessageSubjects = new Map();
        this.log = logService.getLogger('VirtualDevicesService');
        this.zmlInitPromise = zmlWasm.init(fetch(zmlWasmFile))
            .then(res => {
            const dbSource = environment.build === 'production' ? 'public' : 'master';
            Library.setDeviceDbSource(DeviceDbSourceType.WEB_SERVICE, `${environment.apiUrl}/device-db/${dbSource}`);
            this.log.info('ZML WASM initialized', res);
            return '';
        })
            .catch(e => {
            this.log.info('ZML init failed:', e);
            return e.message || String(e);
        });
    }
    getConnection(simulationId, realm) {
        return __awaiter(this, void 0, void 0, function* () {
            const zmlInitError = yield this.zmlInitPromise;
            if (zmlInitError) {
                throw Error(`Cannot get connection because ZML initialization failed: ${zmlInitError}`);
            }
            let connection = this.connections.get(simulationId);
            if (!connection) {
                connection = new MessageRouterConnection(simulationId, realm);
                this.connections.set(simulationId, connection);
                connection.errors.subscribe(err => this.onError(connection, err));
                connection.newMonitorConnection.subscribe(monitorConnection => {
                    this.createMessageSubjectsIfNotPresent(simulationId);
                    const subject = this.connectionMessageSubjects.get(simulationId);
                    monitorConnection.monitorMessages.subscribe(message => subject.next(message), err => this.log.error(err));
                });
                this.newConnectionsSubject.next(connection);
            }
            yield connection.ensureConnected();
            return connection;
        });
    }
    getRoutedConnection(simulationId, realm) {
        return __awaiter(this, void 0, void 0, function* () {
            const routerConnection = yield this.getConnection(simulationId, realm);
            return yield routerConnection.getRoutedConnection();
        });
    }
    getAsciiConnection(simulationId, realm) {
        return __awaiter(this, void 0, void 0, function* () {
            const routedConnection = yield this.getRoutedConnection(simulationId, realm);
            return routedConnection.connection;
        });
    }
    getConnectionMessages(simulationId) {
        this.createMessageSubjectsIfNotPresent(simulationId);
        const subject = this.connectionMessageSubjects.get(simulationId);
        return subject.asObservable();
    }
    dropConnection(simulationId) {
        const connection = this.connections.get(simulationId);
        if (connection) {
            this.removeConnection(connection);
        }
    }
    createMessageSubjectsIfNotPresent(simulationId) {
        if (!this.connectionMessageSubjects.has(simulationId)) {
            this.connectionMessageSubjects.set(simulationId, new Subject());
        }
    }
    onError(connection, err) {
        this.removeConnection(connection);
        this.log.info(`Removing Simulation ID '${connection.simulationId}' due to error: ${err}`);
    }
    removeConnection(connection) {
        if (this.connections.get(connection.simulationId) !== connection) {
            return;
        }
        this.connections.delete(connection.simulationId);
        connection.close().catch(handleUnexpectedError);
    }
};
VirtualDevicesService = __decorate([
    injectable(),
    __param(0, inject(Log)),
    __metadata("design:paramtypes", [Log])
], VirtualDevicesService);
export { VirtualDevicesService };
