import { createReducer } from '../utils';
import { ActionTypes } from './actions';
import { HistoryItemType } from './types';
const MAX_HISTORY_ITEMS = 2048;
const initialState = {
    nextItemKey: 0,
    history: {},
    recentUserCommands: {},
};
const appendToRecentUserCommands = (state, { simulationId, command }) => (Object.assign(Object.assign({}, state), { recentUserCommands: Object.assign(Object.assign({}, state.recentUserCommands), { [simulationId]: (state.recentUserCommands[simulationId] || []).concat([command]) }) }));
const appendToHistory = (state, { simulationId, messages }) => {
    var _a, _b;
    const { nextItemKey } = state;
    const [newHistory, unusedRecentUserCmd, newNextKey] = messages.reduce(([h, uc, k], msg) => {
        const i = uc.indexOf(msg.message);
        if (i < 0) {
            return [
                h.concat([{
                        key: k,
                        text: msg.message,
                        type: msg.source.startsWith('cmd') ? HistoryItemType.OtherRequest : HistoryItemType.Response,
                    }]),
                uc,
                k + 1,
            ];
        }
        else {
            return [
                h.concat([{ key: k, text: msg.message, type: HistoryItemType.LocalUserRequest }]),
                [...uc.slice(0, i), ...uc.slice(i + 1)],
                k + 1,
            ];
        }
    }, [(_a = state.history[simulationId]) !== null && _a !== void 0 ? _a : [], [...(_b = state.recentUserCommands[simulationId]) !== null && _b !== void 0 ? _b : []], nextItemKey]);
    return Object.assign(Object.assign({}, state), { nextItemKey: newNextKey, history: Object.assign(Object.assign({}, state.history), { [simulationId]: newHistory.slice(-MAX_HISTORY_ITEMS) }), recentUserCommands: Object.assign(Object.assign({}, state.recentUserCommands), { [simulationId]: unusedRecentUserCmd }) });
};
const appendErrorToHistory = (state, { simulationId, message }) => (Object.assign(Object.assign({}, state), { nextItemKey: state.nextItemKey + 1, history: Object.assign(Object.assign({}, state.history), { [simulationId]: (state.history[simulationId] || []).concat([{
                key: state.nextItemKey,
                text: message,
                type: HistoryItemType.Error,
            }]).slice(-MAX_HISTORY_ITEMS) }) }));
const clearConnectionHistory = (state, { simulationId }) => (Object.assign(Object.assign({}, state), { history: Object.assign(Object.assign({}, state.history), { [simulationId]: [] }), recentUserCommands: Object.assign(Object.assign({}, state.recentUserCommands), { [simulationId]: [] }) }));
export const reducer = createReducer({
    [ActionTypes.SEND_COMMAND]: appendToRecentUserCommands,
    [ActionTypes.APPEND_TO_HISTORY]: appendToHistory,
    [ActionTypes.APPEND_ERROR_TO_HISTORY]: appendErrorToHistory,
    [ActionTypes.CLEAR_CONNECTION_HISTORY]: clearConnectionHistory,
}, initialState);
